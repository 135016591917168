<template>
  <section>
    <b-field label="Indique las medidas del paquete a recolectar">
      <b-field>
        <b-radio-button
          required
          native-value="menor 2Kg"
          name="peso"
          v-model="peso"
          style="margin: 5px"
        >
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27.0954 3.34566L0.682556 5.84195L2.88483 29.1439L29.2976 26.6476L27.0954 3.34566Z"
              :fill="[isSelected('menor 2Kg') ? 'white' : '#BCBCBC']"
            />
            <path
              d="M27.1092 3.34456L0.682965 5.84212L3.9031 2.38551L23.2042 0.561354L27.1092 3.34456Z"
              :fill="[isSelected('menor 2Kg') ? '#BCBCBC' : '#878787']"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.6974 4.65371L13.3982 1.48807L13.8024 1.44986L14.1016 4.61551L13.6974 4.65371Z"
              fill="white"
            />
            <path
              d="M15.3928 21.7975C13.0559 22.0184 10.9687 20.2096 10.7374 17.7632C10.5062 15.3169 12.2177 13.1491 14.5545 12.9282C16.8914 12.7074 18.9786 14.5162 19.2099 16.9625C19.4411 19.4088 17.7245 21.5772 15.3928 21.7975ZM14.6874 14.3346C13.0918 14.4854 11.923 15.9659 12.0809 17.6363C12.2388 19.3067 13.6642 20.542 15.2599 20.3912C16.8555 20.2404 18.0243 18.7599 17.8664 17.0895C17.7085 15.4191 16.2831 14.1838 14.6874 14.3346Z"
              :fill="[isSelected('menor 2Kg') ? '#BCBCBC' : 'white']"
            />
            <path
              d="M21.336 16.7616L20.9603 16.7971C20.4507 16.8453 19.9724 16.5099 19.835 15.9901C19.19 13.5612 16.9533 11.8752 14.4775 12.1092C14.1686 12.1384 13.8675 12.194 13.5746 12.2815C13.0438 12.435 12.483 12.1618 12.2365 11.6414L12.1009 11.3607C12.7992 11.0011 13.557 10.7772 14.3446 10.7028C17.8499 10.3715 20.9887 13.0867 21.336 16.7616Z"
              :fill="[isSelected('menor 2Kg') ? '#BCBCBC' : 'white']"
            />
          </svg>
          <span>Menor a 2Kg</span>
        </b-radio-button>
        <b-radio-button
          native-value="entre 2Kg y 12Kg"
          name="peso"
          v-model="peso"
          style="margin: 5px"
        >
          <svg
            width="39"
            height="40"
            viewBox="0 0 39 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.93 4.8708L0.71254 8.19983L3.64948 39.2694L38.8669 35.9404L35.93 4.8708Z"
              :fill="[isSelected('entre 2Kg y 12Kg') ? 'white' : '#BCBCBC']"
            />
            <path
              d="M35.948 4.86919L0.712576 8.19992L5.00606 3.59098L30.7412 1.15829L35.948 4.86919Z"
              :fill="[isSelected('entre 2Kg y 12Kg') ? '#BCBCBC' : '#878787']"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.066 6.61508L17.667 2.39417L18.206 2.34322L18.605 6.56413L18.066 6.61508Z"
              fill="white"
            />
            <path
              d="M20.3267 29.4736C17.2108 29.7682 14.4277 27.3564 14.1194 24.0946C13.8111 20.8328 16.0929 17.9423 19.2088 17.6478C22.3246 17.3532 25.1077 19.765 25.416 23.0268C25.7244 26.2886 23.4356 29.1797 20.3267 29.4736ZM19.386 19.5229C17.2585 19.7241 15.7001 21.6981 15.9107 23.9253C16.1212 26.1525 18.0218 27.7996 20.1494 27.5984C22.277 27.3973 23.8353 25.4233 23.6248 23.1961C23.4142 20.9689 21.5136 19.3218 19.386 19.5229Z"
              :fill="[isSelected('entre 2Kg y 12Kg') ? '#BCBCBC' : 'white']"
            />
            <path
              d="M28.2505 22.7588L27.7495 22.8062C27.0701 22.8704 26.4323 22.4233 26.2491 21.7302C25.389 18.4916 22.4067 16.2436 19.1056 16.5557C18.6938 16.5946 18.2923 16.6688 17.9017 16.7855C17.194 16.9901 16.4463 16.6258 16.1176 15.932L15.9368 15.5577C16.8678 15.0782 17.8783 14.7798 18.9283 14.6805C23.6021 14.2387 27.7873 17.8589 28.2505 22.7588Z"
              :fill="[isSelected('entre 2Kg y 12Kg') ? '#BCBCBC' : 'white']"
            />
          </svg>
          <span>Entre 2Kg y 12Kg</span>
        </b-radio-button>
        <b-radio-button
          native-value="entre 12Kg y 20Kg"
          name="peso"
          v-model="peso"
          style="margin: 0 5px"
        >
          <svg
            width="49"
            height="49"
            viewBox="0 0 49 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M47.6566 9.69434L3.60684 5.84048L0.206891 44.7021L44.2566 48.556L47.6566 9.69434Z"
              :fill="[isSelected('entre 12Kg y 20Kg') ? 'white' : '#BCBCBC']"
            />
            <path
              d="M47.679 9.69627L3.60675 5.84045L9.92544 1.14275L42.1148 3.95895L47.679 9.69627Z"
              :fill="[isSelected('entre 12Kg y 20Kg') ? '#BCBCBC' : '#878787']"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.2999 7.80765L25.7618 2.52817L26.436 2.58715L25.9741 7.86663L25.2999 7.80765Z"
              fill="white"
            />
            <path
              d="M22.9214 36.4215C19.0241 36.0806 16.1466 32.4874 16.5035 28.4076C16.8605 24.3277 20.3182 21.2889 24.2155 21.6298C28.1127 21.9708 30.9902 25.5639 30.6333 29.6438C30.2764 33.7236 26.8101 36.7617 22.9214 36.4215ZM24.0103 23.9753C21.3491 23.7425 18.9878 25.8178 18.744 28.6036C18.5003 31.3894 20.4654 33.8432 23.1266 34.0761C25.7877 34.3089 28.1491 32.2336 28.3928 29.4478C28.6365 26.662 26.6714 24.2081 24.0103 23.9753Z"
              :fill="[isSelected('entre 12Kg y 20Kg') ? '#BCBCBC' : 'white']"
            />
            <path
              d="M34.1784 29.9539L33.5518 29.8991C32.7019 29.8248 32.0187 29.1311 31.9498 28.2377C31.6232 24.0618 28.4638 20.6251 24.3348 20.2639C23.8197 20.2188 23.3093 20.2194 22.8028 20.2747C21.8865 20.3666 21.0495 19.7501 20.8019 18.8229L20.6641 18.3218C21.9169 17.9425 23.2266 17.8035 24.54 17.9184C30.3859 18.4299 34.7146 23.8252 34.1784 29.9539Z"
              :fill="[isSelected('entre 12Kg y 20Kg') ? '#BCBCBC' : 'white']"
            />
          </svg>
          <span>Entre 12Kg y 20Kg</span>
        </b-radio-button>
        <b-radio-button
          native-value="mayor 20Kg"
          name="peso"
          v-model="peso"
          style="margin: 0 5px"
        >
          <svg
            width="49"
            height="49"
            viewBox="0 0 49 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M47.6566 9.69434L3.60684 5.84048L0.206891 44.7021L44.2566 48.556L47.6566 9.69434Z"
              :fill="[isSelected('mayor 20Kg') ? 'white' : '#BCBCBC']"
            />
            <path
              d="M47.679 9.69627L3.60675 5.84045L9.92544 1.14275L42.1148 3.95895L47.679 9.69627Z"
              :fill="[isSelected('mayor 20Kg') ? '#BCBCBC' : '#878787']"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.2999 7.80765L25.7618 2.52817L26.436 2.58715L25.9741 7.86663L25.2999 7.80765Z"
              fill="white"
            />
            <path
              d="M22.9214 36.4215C19.0241 36.0806 16.1466 32.4874 16.5035 28.4076C16.8605 24.3277 20.3182 21.2889 24.2155 21.6298C28.1127 21.9708 30.9902 25.5639 30.6333 29.6438C30.2764 33.7236 26.8101 36.7617 22.9214 36.4215ZM24.0103 23.9753C21.3491 23.7425 18.9878 25.8178 18.744 28.6036C18.5003 31.3894 20.4654 33.8432 23.1266 34.0761C25.7877 34.3089 28.1491 32.2336 28.3928 29.4478C28.6365 26.662 26.6714 24.2081 24.0103 23.9753Z"
              :fill="[isSelected('mayor 20Kg') ? '#BCBCBC' : 'white']"
            />
            <path
              d="M34.1784 29.9539L33.5518 29.8991C32.7019 29.8248 32.0187 29.1311 31.9498 28.2377C31.6232 24.0618 28.4638 20.6251 24.3348 20.2639C23.8197 20.2188 23.3093 20.2194 22.8028 20.2747C21.8865 20.3666 21.0495 19.7501 20.8019 18.8229L20.6641 18.3218C21.9169 17.9425 23.2266 17.8035 24.54 17.9184C30.3859 18.4299 34.7146 23.8252 34.1784 29.9539Z"
              :fill="[isSelected('mayor 20Kg') ? '#BCBCBC' : 'white']"
            />
          </svg>
          <span>Más de 20K</span>
        </b-radio-button>
      </b-field>
    </b-field>
    <b-message size="is-small" type="is-warning" has-icon>
      A partir de 20Kg se cobrarán ₡1,000 adicionales por kilo
    </b-message>
  </section>
</template>

<script>
export default {
  name: "PackageWeight",
  data() {
    return {
      peso: "",
    };
  },
  methods: {
    isSelected(value) {
      return value === this.peso
    }
  },
};
</script>
