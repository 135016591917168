<template>
    <b-field grouped>
        <b-field label="Desde:" for="fecha_inicio" label-position="on-border" expanded>
            <b-datepicker
                :date-formatter="dateFormat"
                name="fecha_inicio"
                icon="calendar-alt"
                v-model="startDate"
                :required="required"
                :month-names="monthNames"
                :day-names="dayNames"
                placeholder="Día/Mes/Año" />
        </b-field>
        <b-field label="Hasta:" for="fecha_final" label-position="on-border" expanded>
            <b-datepicker
                :date-formatter="dateFormat"
                name="fecha_final"
                icon="calendar-day"
                v-model="endDate"
                :required="required"
                :month-names="monthNames"
                :day-names="dayNames"
                placeholder="Día/Mes/Año" />
        </b-field>
    </b-field>
</template>

<script>
    export default {
        name: 'StartEndDate',
        props: ['values', 'required'],
        data() {
            return {
                startDate: null,
                endDate: null,
                dateFormat: (date) => date.toLocaleDateString('es-CR'),
                monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                dayNames: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"],
            }
        },
        mounted() {
            if (!Array.isArray(this.values))
            {
                if (this.values.fecha_inicio != undefined)
                {
                    let startDate = this.values.fecha_inicio.split('/')

                    this.startDate = new Date(parseInt(startDate[2]), parseInt(startDate[1])-1, parseInt(startDate[0]))
                }

                if (this.values.fecha_final != undefined)
                {
                    let endDate = this.values.fecha_final.split('/')

                    this.endDate = new Date(parseInt(endDate[2]), parseInt(endDate[1])-1, parseInt(endDate[0]))
                }
            }
            else
            {
                let date = new Date(), y = date.getFullYear(), m = date.getMonth(), d = date.getDate()
                this.startDate = new Date(y, m - 1, d)
                this.endDate = new Date(y, m, d)
            }
        }
    }
</script>
