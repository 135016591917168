<template>
    <section class="allContainer">
        <div class="bodySwiper columns is-multiline">
            <section class="column is-6 swiperModal">
                <div id="cropper-display" class="cropper-monitor">
                    <h2 v-if="isCurrentImageUnset">
                        Seleccione una imagen
                    </h2>
                </div>
                <div class="crooper-controls">
                    <div class="field is-grouped">
                        <div class="field">
                            <label class="label">Modo de edición</label>
                            <div class="field has-addons">
                                <div class="control">
                                    <button class="button" :class="{ 'is-primary' : currentMode === 'move' }" @click="mode('move')">
                                        <span class="icon">
                                            <i class="fas fa-arrows-alt"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button" :class="{ 'is-primary' : currentMode === 'crop' }" @click="mode('crop')">
                                        <span class="icon">
                                            <i class="fas fa-crop-alt"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Zoom</label>
                            <div class="field has-addons">
                                <div class="control">
                                    <button class="button" @click="zoom(.1)">
                                        <span class="icon">
                                            <i class="fas fa-search-plus"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button" @click="zoom(-.1)">
                                        <span class="icon">
                                            <i class="fas fa-search-minus"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Mover</label>
                            <div class="field has-addons">
                                <div class="control">
                                    <button class="button" @click="move(-10, 0)">
                                        <span class="icon">
                                            <i class="fas fa-arrow-left"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button" @click="move(0, -10)">
                                        <span class="icon">
                                            <i class="fas fa-arrow-up"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button" @click="move(0, 10)">
                                        <span class="icon">
                                            <i class="fas fa-arrow-down"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button" @click="move(10, 0)">
                                        <span class="icon">
                                            <i class="fas fa-arrow-right"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Rotar</label>
                            <div class="field has-addons">
                                <div class="control">
                                    <button class="button" @click="rotate(-5)">
                                        <span class="icon is-small">
                                            <i class="fas fa-undo"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button" @click="rotate(-45)">
                                        <span class="icon is-medium">
                                            <i class="fas fa-undo"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button" @click="rotate(45)">
                                        <span class="icon is-medium">
                                            <i class="fas fa-redo"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button" @click="rotate(5)">
                                        <span class="icon is-small">
                                            <i class="fas fa-redo"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="field is-grouped">
                        <div class="field">
                            <label class="label">Voltear</label>
                            <div class="field has-addons">
                                <div class="control">
                                    <button class="button" @click="flip('x')">
                                        <span class="icon">
                                            <i class="fas fa-arrows-alt-h"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button" @click="flip('y')">
                                        <span class="icon">
                                            <i class="fas fa-arrows-alt-v"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Opciones</label>
                            <div class="field is-grouped">
                                <div class="control">
                                    <button class="button" @click="reset">
                                        <span class="icon">
                                            <i class="fas fa-times"></i>
                                        </span>
                                        <span>Eliminar cambios</span>
                                    </button>
                                </div>
                                <div class="control">
                                    <button class="button is-primary" @click="download">
                                        <span class="icon">
                                            <i class="fas fa-download"></i>
                                        </span>
                                        <span>Descargar</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="column is-6">
                <div class="columns is-multiline">
                    <div class="column is-12">
                        <h5 class="title is-5">Preview de ID</h5>
                        <div class="signaturePreview">
                            <div class="actionsImgs buttons">
                                <i class="fas fa-redo button is-primary rotateLeft"
                                    @click="changeRotationCurrentDisplay(90)"></i>
                                <i class="fas fa-redo button is-primary rotateRight"
                                    @click="changeRotationCurrentDisplay(-90)"></i>
                            </div>
                            <img :src="firstImage.imgUrl" :alt="firstImage.imgDescription" id="first-image-loaded"
                                :data-ratio="firstImage.imgOrientation" data-rotate="270">
                        </div>
                    </div>
                    <div class="column is-12">
                        <h5 v-show="!oldDocument" class="title is-5">Acciones</h5>
                        <div class="buttons">
                            <template v-if="completado && !oldDocument">
                                <register-product-entry :api="entryAPI" :idproducto="idProducto"></register-product-entry>
                            </template>
                        </div>
                        <div class="images-gallery">
                            <template v-for="(image, i) in images">
                                <span v-if="!image.imgUrl.includes('optional')" class="has-margin-5 image-selector" :key="i" @click="imageSelected(image)">
                                    <img :src="image.imgUrl" :alt="image.imgDescription">
                                </span>
                            </template>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    .image-selector
    {
        height: 100px;
        width: 100px;
        display: inline-block;
        margin: 5px;
        cursor: pointer;
    }
    .crooper-monitor
    {
        height: 70%;
        max-height: 70%;
    }
    .crooper-controls
    {
        height: 30%;
    }
</style>

<script>
import Cropper from 'cropperjs'
import FileSaver from 'file-saver'

    export default {
        name: "SwiperGallery",
        props: {
            images: Array,
            slides: Number,
            entryAPI: {
                type: String,
                required: false,
                default: '/api/asm/asm_tracking_consultas_detalles_cliente',
            },
            oldDocument: {
                default: false,
                type: Boolean
            }
        },
        watch: {
            'images': function (newVal, oldVal) {
                this.firstImage = newVal[0]
                this.imageSelected(this.firstImage)
            }
        },
        data() {
            return {
                currentImageIndex: 0,
                currentImage: {},
                currentImageLoaded: Image,
                imageDisplay: HTMLDivElement,
                idProducto: 0,
                completado: false,
                cropper: null,
                currentMode: 'crop',
                firstImage: {},
                firstImageLoaded: null,
            }
        },
        computed: {
            isCurrentImageUnset() {
                return _.isEmpty(this.currentImage)
            }
        },
        mounted() {
            this.imageDisplay = document.querySelector('#cropper-display')
            this.firstImageLoaded = document.querySelector('#first-image-loaded')
            let url = new URL(window.location.href)
            this.completado = url.searchParams.get('completado') == 1

            this.idProducto = Number.parseInt(window.location.pathname.split("/").pop())
        },
        methods: {
            imageSelected(image) {

                if (this.cropper !== null)
                {
                    this.cropper.destroy()
                    this.cropper = null
                }

                if (this.currentImageLoaded.length === undefined)
                    this.imageDisplay.removeChild(this.currentImageLoaded)

                this.currentImage = image

                this.currentImageLoaded = new Image()
                this.currentImageLoaded.src = this.currentImage.imgUrl
                this.currentImageLoaded.alt = this.currentImage.imgDescription
                this.imageDisplay.appendChild(this.currentImageLoaded)

                this.cropper = new Cropper(this.currentImageLoaded, { checkCrossOrigin: false })
                this.cropper.setAspectRatio(NaN)
            },
            download()
            {
                const picture = this.cropper.getCroppedCanvas()

                picture.toBlob(blob => {
                    FileSaver.saveAs(blob, this.imgName)
                }, 'image/jpeg', 1)
            },
            mode(value)
            {
                this.currentMode = value
                this.cropper.setDragMode(value)
            },
            zoom(value)
            {
                this.cropper.zoom(value)
            },
            move(valorX, valorY)
            {
                this.cropper.move(valorX, valorY)
            },
            rotate(value)
            {
                this.cropper.rotate(value)
            },
            flip(value)
            {
                if (value === 'x')
                {
                    this.cropper.scaleX(this.cropper.getData().scaleX * -1)
                }
                else
                {
                    this.cropper.scaleY(this.cropper.getData().scaleY * -1)
                }
            },
            reset()
            {
                this.cropper.reset()
                this.cropper.setAspectRatio(NaN)
                this.cropper.setDragMode(this.currentMode)
            },
            changeRotationCurrentDisplay(degrees) {
                let rotation = Number.parseInt(this.firstImageLoaded.dataset.rotate)
                rotation = (rotation + degrees) % 360
                this.firstImageLoaded.dataset.rotate = rotation
            }
        }
    }

</script>
