<template>
    <div ref="gmapContainer" id="gmap-container">
        <div v-if="coordinates.lat != 0.0 && coordinates.lng != 0.0" style="width: 100%; height: 100%;">
            <GmapMap
                :center="{ lat: coordinates.lat, lng: coordinates.lng }"
                :zoom="zoom"
                map-type-id="terrain"
                style="width: 100%; height: 100%;"
                :options="{
                    streetViewControl: false,
                }">
                <GmapMarker :position="coordinates" :draggable="false"/>
            </GmapMap>
        </div>
        <div v-else>
            <div class="map-not-found">
                Ubicación de la última visita no encontrada
            </div>
        </div>
    </div>
</template>
<style>
#gmap-container
{
    width: 100%;
    height: 80%;
}
</style>
<script>
    import * as GoogleMaps from 'vue2-google-maps'
    import vue from 'vue'

    vue.use(GoogleMaps, {
        load: {
            key: 'AIzaSyBQH4ppr3zGrh5AE_Ib3Sth6rqadUUW4xs',
            libraries: 'places',
        }
    })

    export default {
        name: 'GmapsWrapper',
        props: {
            confirmationDetails: Array,
        },
        data() {
            return {
                coordinates: {lat: 0.0, lng: 0.0},
                zoom: 17,
                container: HTMLElement
            }
        },
        mounted() {
            let detailsFound = false, detailsIndex = 0

            this.confirmationDetails.sort((a, b) => {
                return a.id_confirmacion - b.id_confirmacion
            })

            for (let index = 0; index < this.confirmationDetails.length; index++)
            {
                const element = this.confirmationDetails[index]
                if (element.details)
                {
                    detailsFound = true
                    detailsIndex = index
                }
            }

            if (detailsFound)
            {
                let visitCoordinates = this.confirmationDetails[detailsIndex].details.coordenadas_registro.split("+")
                this.coordinates.lat = parseFloat(visitCoordinates[0])
                this.coordinates.lng = parseFloat(visitCoordinates[1])
                this.container = document.getElementById('gmap-container')
            }
        },
        methods: {
        }
    }
</script>
