<template>
    <section>
        <div class="columns">
            <div class="column is-2">
                <section>
                    <div class="field">
                        <label class="label">Modo de edición</label>
                        <div class="field has-addons">
                            <div class="control">
                                <button class="button" :class="{ 'is-primary' : currentMode === 'move' }" @click="mode('move')">
                                    <span class="icon">
                                        <i class="fas fa-arrows-alt"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="control">
                                <button class="button" :class="{ 'is-primary' : currentMode === 'crop' }" @click="mode('crop')">
                                    <span class="icon">
                                        <i class="fas fa-crop-alt"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Zoom</label>
                        <div class="field has-addons">
                            <div class="control">
                                <button class="button" @click="zoom(.1)">
                                    <span class="icon">
                                        <i class="fas fa-search-plus"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="control">
                                <button class="button" @click="zoom(-.1)">
                                    <span class="icon">
                                        <i class="fas fa-search-minus"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Mover</label>
                        <div class="field has-addons">
                            <div class="control">
                                <button class="button" @click="move(-10, 0)">
                                    <span class="icon">
                                        <i class="fas fa-arrow-left"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="control">
                                <button class="button" @click="move(0, -10)">
                                    <span class="icon">
                                        <i class="fas fa-arrow-up"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="control">
                                <button class="button" @click="move(0, 10)">
                                    <span class="icon">
                                        <i class="fas fa-arrow-down"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="control">
                                <button class="button" @click="move(10, 0)">
                                    <span class="icon">
                                        <i class="fas fa-arrow-right"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Rotar</label>
                        <div class="field has-addons">
                            <div class="control">
                                <button class="button" @click="rotate(-5)">
                                    <span class="icon is-small">
                                        <i class="fas fa-undo"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="control">
                                <button class="button" @click="rotate(-45)">
                                    <span class="icon is-medium">
                                        <i class="fas fa-undo"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="control">
                                <button class="button" @click="rotate(45)">
                                    <span class="icon is-medium">
                                        <i class="fas fa-redo"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="control">
                                <button class="button" @click="rotate(5)">
                                    <span class="icon is-small">
                                        <i class="fas fa-redo"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Voltear</label>
                        <div class="field has-addons">
                            <div class="control">
                                <button class="button" @click="flip('x')">
                                    <span class="icon">
                                        <i class="fas fa-arrows-alt-h"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="control">
                                <button class="button" @click="flip('y')">
                                    <span class="icon">
                                        <i class="fas fa-arrows-alt-v"></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <button class="button" @click="reset">
                                <span class="icon">
                                    <i class="fas fa-times"></i>
                                </span>
                                <span>Eliminar cambios</span>
                            </button>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <button class="button is-primary" @click="download">
                                <span class="icon">
                                    <i class="fas fa-download"></i>
                                </span>
                                <span>Descargar</span>
                            </button>
                        </div>
                    </div>
                </section>
            </div>
            <div class="column is-10">
                <img :src="img_path + imgSrc" id="img_cropper" class="img-cropper">
            </div>
        </div>
    </section>
</template>

<script>
import Cropper from 'cropperjs'
import FileSaver from 'file-saver'

    export default {
        props: ['imgSrc'],
        data() {
            return {
                cropper: null,
                img_path: process.env.MIX_IMG_PATH,
                currentMode: 'crop'
            }
        },
        computed: {
            imgName() {
                return this.imgSrc.replace(/^.*[\\\/]/, '')
            }
        },
        mounted() {
            const area = document.getElementById('img_cropper')
            this.cropper = new Cropper(area)
            this.cropper.setAspectRatio(NaN)
        },
        methods: {
            download()
            {
                const picture = this.cropper.getCroppedCanvas()

                picture.toBlob(blob => {
                    FileSaver.saveAs(blob, this.imgName)
                }, 'image/jpeg', 1)
            },
            mode(value)
            {
                this.currentMode = value
                this.cropper.setDragMode(value)
            },
            zoom(value)
            {
                this.cropper.zoom(value)
            },
            move(valorX, valorY)
            {
                this.cropper.move(valorX, valorY)
            },
            rotate(value)
            {
                this.cropper.rotate(value)
            },
            flip(value)
            {
                if (value === 'x')
                {
                    this.cropper.scaleX(this.cropper.getData().scaleX * -1)
                }
                else
                {
                    this.cropper.scaleY(this.cropper.getData().scaleY * -1)
                }
            },
            reset()
            {
                this.cropper.reset()
                this.cropper.setAspectRatio(NaN)
                this.cropper.setDragMode(this.currentMode)
            }
        }
    }
</script>
