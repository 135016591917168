<template>
    <div class="quickAccess">
        <h3 class="title is-3 is-full">
            Accesos rápidos
        </h3>

        <div class="is-flex">
            <a :href=" rootPath + '/system/panel/pages/panel_charts'" class="button is-medium is-primary">
                Ir a gráficos generales
            </a>
            <div class="buttons is-flex">
                <a v-for="(page, index) in pages" :key="index" :href=" rootPath + page.href " class="item">
                    <span class="icon">
                        <i :class="'fas fa-' + page.icon"></i>
                    </span>
                    {{ page.label }}
                </a>
            </div>
        </div>
<hr>

    </div>
</template>

<script>
    export default {
        name: 'RecentPages',
        props: {
            api: String,
            callTo: String,
        },
        data() {
            return {
                pages: [],
                rootLocation: process.env.MIX_APP_ENV,
            }
        },
        computed: {
            rootPath() {
                return this.rootLocation.length > 0 ? '/' + this.rootLocation : ''
            }
        },
        created() {
            this.pages = JSON.parse(sessionStorage.getItem('visitPages')) || []
        },
        mounted() {},
        methods: {}
    }

</script>
