<template>
    <div>
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><a :href="'/'">Inicio</a></li>
                <li v-for="(p, i) in pages" :key="i">
                    <a :href="'/' + p.folder.folder_path + p.page_url">{{ p.page_description }}</a>
                </li>
            </ul>
        </nav>
        <h1 class="title is-1">{{ page.page_description }}</h1>
    </div>
</template>

<script>
    export default {
        name: 'Breadcrumbs',
        props: {
            page: {},
            previousUrl: String,
        },
        data() {
            return {
                pages: [],
                rootLocation: process.env.MIX_APP_ENV ? process.env.MIX_APP_ENV : '' ,
            }
        },
        mounted() {
            let page = this.page
            this.pages = JSON.parse(sessionStorage.getItem('breadcrumbs')) || []

            let urlParameters = (window.location.href.split('?')), getQuery = '', id = 0, checkId = ''

            if (urlParameters.length > 1)
                getQuery = urlParameters[1]

            checkId = urlParameters[0].substring(urlParameters[0].lastIndexOf('/') + 1)

            if (!isNaN(checkId))
                id = Number.parseInt(checkId)

            let exist = _.findIndex(this.pages, p => p.page_id == page.page_id)

            if (exist === -1)
            {
                if (id)
                    page.page_url = page.page_url + '/' + id

                if (getQuery)
                    page.page_url = page.page_url + '?' + getQuery
                this.pages.push(page)
            }
            else
            {
                let getsOfPage = this.pages[exist]['page_url'].split('?')
                let urlFromPage = getsOfPage[0]

                if (id)
                {
                    let existingId = urlFromPage.substring(urlFromPage.lastIndexOf('/') + 1)

                    if (!isNaN(existingId))
                    {
                        urlFromPage = urlFromPage.replace(existingId, id)
                    }
                    else
                    {
                        urlFromPage = urlFromPage + '/' + id
                    }

                    this.pages[exist]['page_url'] = urlFromPage
                }

                if (getQuery)
                {

                    let alreadyExist = _.findIndex(getsOfPage, g => g === getQuery)

                    if (alreadyExist === -1)
                        this.pages[exist]['page_url'] = this.pages[exist]['page_url'] + '?' + getQuery
                }
            }

            sessionStorage.setItem('breadcrumbs', JSON.stringify(this.pages))
        },
        methods: {
        }
    }
</script>
