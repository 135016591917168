<template>
    <div>
        <b-message title="Ubicaciones" :closable="false">
            <b-loading :active="loading" />
            <!-- <b-field label="País" label-position="on-border" expanded>
                <b-select name="pais" v-model="selectedCountry" expanded @change.native="loadProvinces">
                    <option v-for="location in locations" :value="location.location_id" :key="location.location_id">
                        {{ location.location_description }}
                    </option>
                </b-select>
            </b-field> -->
            <b-field grouped>
                <b-field label="Provincia" label-position="on-border" expanded>
                    <b-select
                        name="provincia"
                        v-model="selectedProvince"
                        expanded
                        @change.native="loadCounty"
                    >
                        <option v-if="provinces == null" disabled>
                            Seleccione un país
                        </option>
                        <template v-else>
                            <option
                                v-for="province in provinces"
                                :value="province.location_id"
                                :key="province.location_id"
                            >
                                {{ province.location_description }}
                            </option>
                        </template>
                    </b-select>
                </b-field>
                <b-field label="Cantón" label-position="on-border" expanded>
                    <b-select
                        name="canton"
                        v-model="selectedCounty"
                        expanded
                        @change.native="loadDistricts"
                    >
                        <option v-if="counties == null" disabled>
                            Seleccione una provincia
                        </option>
                        <template v-else>
                            <option
                                v-for="county in counties"
                                :value="county.location_id"
                                :key="county.location_id"
                            >
                                {{ county.location_description }}
                            </option>
                        </template>
                    </b-select>
                </b-field>
                <b-field label="Distrito" label-position="on-border" expanded>
                    <b-select
                        name="distrito"
                        v-model="selectedDistrict"
                        expanded
                    >
                        <option v-if="districts == null" disabled>
                            Seleccione una provincia
                        </option>
                        <template v-else>
                            <option
                                v-for="district in districts"
                                :value="district.location_id"
                                :key="district.location_id"
                            >
                                {{ district.location_description }}
                            </option>
                        </template>
                    </b-select>
                </b-field>
            </b-field>

            <b-field grouped>
                <b-field label="Zona Restringida: ">
                    <input
                        type="checkbox"
                        id="restricted"
                        name="restricted"
                        v-model="checked"
                    />
                    (Solo mostrar zonas restringidas)
                </b-field>
            </b-field>
            <b-field grouped>
                <b-field label="Días" label-position="on-border">
                    <b-select
                        name="day"
                        v-model="selectedDays"
                        :disabled="checked"
                    >
                        <option v-if="days == null">Seleccione una día</option>
                        <template v-else>
                            <option
                                v-for="d in days"
                                :value="d != '--------' ? d : ''"
                                :key="d"
                            >
                                {{ d }}
                            </option>
                        </template>
                    </b-select>
                </b-field>
            </b-field>
            <button
                type="submit"
                class="button is-primary"
                @click="loading = true"
            >
                Buscar
            </button>
        </b-message>
    </div>
</template>

<script>
export default {
    name: "SelectBasicLocation",
    props: {
        locations: Array,
        values: null,
    },
    data() {
        return {
            selectedCountry: 0,
            provinces: null,
            selectedProvince: null,
            counties: null,
            selectedCounty: null,
            districts: null,
            days: null,
            selectedDistrict: null,
            selectedDays: null,
            loading: false,
            checked: false,
        };
    },
    mounted() {
        this.checked = this.values.restricted ? true : false;
        this.selectedDays = this.values.day;
        this.days = [
            "--------",
            "Lunes",
            "Martes",
            "Miercoles",
            "Jueves",
            "Viernes",
        ];
        this.loadProvinces();
        this.selectedProvince = this.values.provincia;
        this.loadCounty();
        this.selectedCounty = this.values.canton;
        this.loadDistricts();
        this.selectedDistrict = this.values.distrito;
    },
    methods: {
        loadProvinces() {
            this.provinces = null;
            this.counties = null;
            this.selectedProvince = null;
            this.counties = null;
            this.selectedCounty = null;
            this.selectedDistrict = null;
            this.provinces = this.locations[0].childs;
            this.provinces.unshift({
                location_id: 0,
                location_description: "-----------------",
            });
        },
        loadCounty() {
            this.districts = null;
            if (this.selectedProvince) {
                this.selectedDistrict = null;
                this.counties = this.provinces.find(
                    (x) => x.location_id == this.selectedProvince
                ).childs;
                this.counties.unshift({
                    location_id: 0,
                    location_description: "-----------------",
                });
            }
        },
        loadDistricts() {
            if (this.selectedCounty)
                this.districts = this.counties
                    .find((x) => x.location_id == this.selectedCounty)
                    .childs.filter((x) => {
                        return x.location_category_id === "Distrito";
                    });
            this.districts.unshift({
                location_id: 0,
                location_description: "-----------------",
            });
        },
    },
};
</script>
