<template>
    <b-field grouped>
        <b-field label="Tipo de producto:" for="tipo_producto" label-position="on-border" expanded>
            <b-select expanded :required="required" v-model="typeSelected" @input="typeChanged" name="tipo_producto" placeholder="Seleccione un tipo" icon="box">
                <option :value=this.all v-if="!required">Todos los tipos</option>
                <option v-for="type in types" :value="type.id_tipo_producto" :key="type.id_tipo_producto">
                    {{ type.descripcion }}
                </option>
            </b-select>
        </b-field>
        <b-field label="Subtipo de producto:" label-position="on-border" for="subtipo_producto" expanded>
            <b-select expanded class="is-fullwidth" :required="required" v-model="subtypeSelected" @input="subtypeChanged" name="subtipo_producto" placeholder="Seleccione un subtipo" icon="pallet">
                <template v-if="!subtypesLoaded">
                    <option disabled>Seleccione un tipo</option>
                </template>
                <template v-else>
                    <option :value=this.all>Todos los subtipos</option>
                    <option v-for="subtype in subtypesLoaded" :value="subtype.id_tipo_producto" :key="subtype.id_tipo_producto">
                        {{ subtype.descripcion }}
                    </option>
                </template>
            </b-select>
        </b-field>
        <!-- <b-field label="Corte/Lote" label-position="on-border" for="corte_lote" expanded>
            <b-select expanded class="is-fullwidth" name="corte_lote" :required="required" placeholder="Seleccione un corte/lote" icon="boxes" v-model="batchSelected" @input="batchChanged">
                <template v-if="!batchsLoaded">
                    <option disabled>Seleccione un subtipo</option>
                </template>
                <template v-else>
                    <option :value=this.all>Todos los cortes/lotes</option>
                    <option v-for="batch in batchsLoaded" :key="batch.id_corte" :value="batch.id_corte">
                        {{ batch.descripcion }}
                    </option>
                </template>
            </b-select>
        </b-field> -->
        <b-field v-show="hasLocations" label="Sedes:" for="sede" label-position="on-border" expanded>
            <b-select name="sede" placeholder="Seleccione una sede" icon="home" v-model="locationSelected">
                <option value="all">No aplica</option>
                <option v-for="(location, index) in locations" :key="index" :value="location.id_sede">
                    {{ location.nombre_sede }}
                </option>
            </b-select>
        </b-field>
        <input type="hidden" name="lista_cortes_lotes" :value="lastBatchs" id="lista_cortes_lotes">
    </b-field>
</template>

<script>
    export default {
        name: "SelectProductType",
        props: ['types', 'values', 'required', 'locations', 'hasLocations'],
        data() {
            return {
                typeSelected: null,
                subtypeSelected: null,
                batchSelected: null,
                subtypesLoaded: null,
                batchsLoaded: null,
                lastBatchs: null,
                locationSelected: null,
                all: "all"
            }
        },
        mounted() {
            if (this.values != null)
            {
                this.typeSelected = this.values.tipo_producto
                this.locationSelected = this.values.sede

                if (this.typeSelected != null && this.typeSelected != this.all)
                {
                    this.typeChanged()
                    this.subtypeSelected = this.values.subtipo_producto

                    if (this.subtypeSelected != null)
                    {
                        this.subtypeChanged()
                    }

                    this.batchSelected = this.values.corte_lote
                    this.batchChanged()
                }
            }
        },
        methods: {
            typeChanged() {
                if (this.typeSelected != this.all)
                {
                    this.subtypesLoaded = this.types.find( x => x.id_tipo_producto == this.typeSelected).subtypes
                    this.subtypeSelected = this.all
                    this.batchSelected = this.all
                    this.subtypeChanged()
                    this.batchChanged()
                }
                else
                {
                    this.subtypeSelected = null
                    this.batchSelected = null
                    this.subtypesLoaded = null
                    this.batchsLoaded = null
                }

                this.productTypeChanged()
            },
            subtypeChanged()
            {
                if (this.subtypeSelected == this.all)
                {
                    let subtypes = this.types.find( x => x.id_tipo_producto == this.typeSelected).subtypes
                    let batchs = []

                    subtypes.forEach
                    (
                        function (subtypeIndex)
                        {
                            Array.prototype.push.apply(batchs, subtypeIndex.batch)
                        }
                    )

                    this.batchsLoaded = batchs
                }
                else
                    this.batchsLoaded = this.subtypesLoaded.find( x => x.id_tipo_producto == this.subtypeSelected).batch

                this.batchSelected = this.batchSelected == this.all ? this.batchSelected : null
                this.batchChanged()
            },
            batchChanged() {
                if (this.batchSelected == this.all)
                {
                    let batchs = []

                    this.batchsLoaded.forEach
                    (
                        function (batch)
                        {
                            batchs.push(batch.id_corte)
                        }
                    )

                    this.lastBatchs = batchs
                }
                else
                {
                    this.lastBatchs = null
                }
            },
            productTypeChanged()
            {
                this.$eventBus.$emit('product-type-changed', this.typeSelected)
            }
        }
    }
</script>
