<template>
  <section class="hero is-fullheight lightboxGeneral" :class="{ 'open' : isActive }">
    <div class="hero-body">
        <div class="containerModal" v-if="images">
            <section class="md-subhead">
                <h2 class="title is-2">Gestor de documentos</h2>
                <button class="delete" @click="deactive"></button>
            </section>
            <swiper-gallery :images="images" :slides="slides" :entryAPI="entryRegisterApi" :old-document="oldDocument" />
        </div>
    </div>
    <div class="bgModal" @click="deactive"></div>
  </section>
</template>

<script>
export default {
  name: "SwiperManager",
  props: {
      clientId: {
          type: Number,
          default: 0,
          required: false,
      },
  },
  data() {
    return {
      isActive: false,
      body: HTMLBodyElement,
      images: Array,
      slides: Number,
    };
  },
  created() {
      this.images = []
      this.slides = 0
      this.$eventBus.$on('active-gallery', images => {
          this.slides = images.length
          this.images = images
          this.active()
      })
  },
  mounted() {
    this.body = document.body
    this.lightBoxGallery = document.querySelector(".lightboxGeneral")
  },
  methods: {
    active() {
      this.isActive = true;
      document.body.classList.add("open")
    },
    deactive() {
      this.isActive = false;
      document.body.classList.remove("open")
    }
  },
  computed: {
      entryRegisterApi() {
          if (this.clientId !== 135)
            return '/api/asm/asm_tracking_consultas_detalles_cliente'
        else
            return '/api/asm/asm_tracking_consultas_detalles_cliente_completo_autogestion'
      },
      oldDocument() {
          return _.some(this.images, {oldDocument: true})
      }
  }
};
</script>
