<template>
  <div>
    <div v-if="hasUserClients">
      <form>
        <div class="select is-primary" :class="{ 'is-loading': isLoading }">
          <select
            name="id"
            class="select-custom"
            @change="changeClient"
            :disabled="isLoading"
            style="color: #f26009"
          >
            <option
              disabled="disabled"
              :selected="hasUserClients"
              value=""
              hidden="hidden"
            >
              Cliente
            </option>
            <option
              v-for="client in clientList"
              :key="client.cliente_id"
              :value="client.cliente_id"
              :selected="clientId === client.cliente_id"
            >
              {{ client.cliente_nombre }}
            </option>
          </select>
        </div>
      </form>
    </div>
    <div v-else>
      <a :href="'/' + rootLink">
        {{ clientName }}
      </a>
    </div>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
export default {
  name: "ClientChanger",
  props: {
    clientName: {
      type: String,
      default: "",
    },
    clientId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      clientList: [],
      isLoading: false,
      currentClient: 0,
    };
  },
  async created() {
    this.isLoading = true;
    try {
      let request = await axios.post("/system/panel/client_list");
      this.clientList = request.data;
      this.isLoading = false;
    } catch (e) {
      Toast.open({
        message: "No se pudo importar lista de clientes",
        duration: 5000,
        type: "is-danger",
        position: "is-bottom-right",
      });
      this.isLoading = false;
    }
  },
  watch: {
    clientId: function (val) {
      this.currentClient = val;
    },
  },
  methods: {
    async changeClient(e) {
      this.isLoading = true;
      let id = e.target.value;

      try {
        await axios.post("/system/panel/swap_client", { id: id });
        Toast.open({
          message: "Cliente cambiado",
          duration: 5000,
          type: "is-success",
          position: "is-bottom-right",
        });
        this.currentClient = id;
        this.$emit("client_change", id);
        this.isLoading = false;
      } catch (ex) {
        Toast.open({
          message: "No se pudo cambiar el cliente",
          duration: 5000,
          type: "is-danger",
          position: "is-bottom-right",
        });
        this.isLoading = false;
        e.target.value = this.currentClient;
      }
    },
    // create submit event when changing client
    // inform if was succesful or not
  },
  computed: {
    hasUserClients() {
      return this.clientList.length > 0;
    },
    rootLink() {
      return process.env.MIX_APP_ENV;
    },
  },
};
</script>
