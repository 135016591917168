<template>
    <b-field grouped>
        <b-field label="Estado track global" for="track_global" label-position="on-border" expanded>
            <b-select class="is-fullwidth" name="track_global" placeholder="Seleccione un estado de track" icon="map-marker-alt" :disabled="productType == null" v-model="trackSelected" @input="trackChanged()">
                <option :value="all">No aplica</option>
                <option v-for="trackGlobal in tracking" :value="trackGlobal.id_track_global" :key="trackGlobal.id_track_global">
                    {{ trackGlobal.descripcion_track_global }}
                </option>
            </b-select>
        </b-field>
        <b-field label="Estado proceso" for="status_process" label-position="on-border" expanded>
            <b-select class="is-fullwidth" name="status_process" v-model="statusSelected" placeholder="Seleccione un estado del proceso" icon="tasks" :disabled="productType == null">
                <option v-if="statusLoaded == null" disabled>Seleccione un estado global</option>
                <option :value="all" v-else>No aplica</option>
                <option v-for="status in statusLoaded" :value="status.id_track_proceso" :key="status.id_track_proceso">
                    {{ status.descripcion_track_proceso }}
                </option>
            </b-select>
        </b-field>
        <!-- <b-field label="Prioridad" for="prioridad" label-position="on-border" expanded>
            <b-select class="is-fullwidth" name="prioridad" placeholder="Seleccione un tipo de prioridad" icon="traffic-light" v-model="prioritySelected">
                <option :value="all">Todas las prioridades</option>
                <option :value="1">Normal</option>
                <option :value="2">Naranja</option>
                <option :value="3">Roja</option>
            </b-select>
        </b-field> -->
    </b-field>
</template>

<script>
    export default {
        name: "SelectStatusProducts",
        props: ['tracking', 'values'],
        data() {
            return {
                trackSelected: null,
                statusLoaded: null,
                statusSelected: null,
                productType: null,
                prioritySelected:null,
                all: "all"
            }
        },
        created() {
            this.$eventBus.$on('product-type-changed', (productType) => {
                this.productType = productType != this.all ? productType : null

                if (productType == this.all)
                {
                    this.productType = null
                    this.trackSelected = null
                    this.statusSelected = null
                    this.statusLoaded = null
                }

                if (this.trackSelected && this.productType != null)
                {
                    this.trackChanged()
                }
            })
        },
        mounted() {
            if (this.values != null)
            {
                this.trackSelected = this.values.track_global

                if (this.trackSelected != null && this.trackSelected != this.all)
                {
                    this.trackChanged()
                    this.statusSelected = this.values.status_process
                }

                this.prioritySelected = this.values.prioridad
            }
        },
        methods: {
            trackChanged()
            {
                if (this.trackSelected != this.all)
                {
                    this.statusLoaded = this.tracking[this.trackSelected][this.productType]
                }
                else
                {
                    this.statusLoaded = null
                }

                this.statusSelected = null
            }
        }
    }
</script>
