<template>
    <aside class="techie-notifications-bar">
        <header class="techie-notifications-header">
            <h1 class="is-size-5"><strong>Notificaciones Techie</strong></h1>
            <b-button icon-right="times" outlined type="is-danger" size="is-small" @click="closeNotifications"></b-button>
        </header>
        <div class="techie-notification-body">
            <div class="card" v-for="request in techieRequests" :key="request.solicitud_id" >
                <header class="card-header">
                    <div class="card-header-title">
                        <div class="has-text-left is-size-7">
                            <ul>
                                <li :class="stateColor(request.estado_solicitud_id)" class="has-text-weight-bold" style="list-style: disc;">{{ request.nombre_estado }}</li>
                            </ul>
                            <div class="columns">
                                <span class="column has-text-left">
                                    Respuesta de <span class="has-text-weight-bold is-italic">Istmo Center</span>
                                </span>
                                <span class="column has-text-right has-text-weight-bold is-italic">
                                    {{ new Date(request.fecha_solicitud).toLocaleDateString('es-CR') }}, {{ new Date(request.fecha_solicitud).toLocaleTimeString() }}
                                </span>
                            </div>
                        </div>
                    </div>
                </header>
                <div class="card-content">
                    <h1 class="subtitle">{{ request.descripcion_solicitud }}</h1>
                    <p>{{ request.notas_solicitud }}</p>
                </div>
                <footer class="class-footer">
                    <span class="card-footer-item">
                        <a v-show="request.id_producto" :href="'/app/asm/asm_tracking_consultas_detalles_cliente/' + request.id_producto" class="button has-margin-right-10">
                            <span class="icon">
                                <i class="fas fa-eye"></i>
                            </span>
                            <span>Ver producto</span>
                        </a>
                        <a :href="'/app/techie/techie_clientes_comunicaciones/' + request.solicitud_id" class="button is-warning">
                            <span class="icon">
                                <i class="fas fa-eye"></i>
                            </span>
                            <span>Ver caso</span>
                        </a>
                    </span>
                </footer>
            </div>
        </div>
    </aside>
</template>

<script>
    export default {
        name: 'TechieNotifications',
        props: {
            api: String,
            hasTechie: Boolean,
        },
        data() {
            return {
                notificationBar: HTMLElement,
                notificationBody: HTMLElement,
                notificationHeader: HTMLElement,
                techieRequests: Array,
            }
        },
        created() {
            this.$eventBus.$on('open-notifications-techie', () => {
                this.openNotifications()
            })
            if (this.hasTechie)
            {
                axios
                    .post(this.api, { page: this.page, method: this.method })
                    .then(response => {
                        let notificationCounter = 0
                        this.techieRequests = response.data.techieRequests

                        _.forEach(this.techieRequests, techie => {
                            let communication = _.last(techie.communications)

                            if (communication) {
                                if (communication.usuario)
                                    notificationCounter++
                            }
                        })


                        this.$eventBus.$emit('show-counter', notificationCounter)
                    })
                    .catch(error => {
                    })
            }
        },
        mounted() {
            this.notificationBar = document.querySelector(".techie-notifications-bar")
            this.notificationHeader = document.querySelector(".techie-notifications-bar .techie-notifications-header")
            this.notificationBody = document.querySelector(".techie-notifications-bar .techie-notification-body")
        },
        methods: {
            openNotifications()
            {
                this.notificationBar.style.visibility = "visible"
                this.notificationBar.style.opacity = 1
                this.notificationBar.style.width = "25%"
                setTimeout(() => {
                    this.notificationBody.style.opacity = 1
                    this.notificationHeader.style.opacity = 1
                }, 300)
            },
            closeNotifications()
            {
                this.notificationBody.style.opacity = 0
                this.notificationHeader.style.opacity = 0
                this.notificationBar.style.width = "0"
                this.notificationBar.style.visibility = "hidden"
            },
            stateColor(state)
            {
                let color

                switch (state)
                {
                    case 1:
                        color = 'has-text-warning'
                        break
                    case 6:
                        color = 'has-text-success'
                        break
                    case 7:
                        color - 'has-text-danger'
                    default:
                        break
                }

                return color
            }
        }
    }
</script>
