<template>
  <div class="navbar is-fixed-top" id="navbar_id">
    <div class="bgDark" @click="hideMenu"></div>
    <b-navbar class="container" type="is-primary" style="z-index: 6">
      <template slot="brand">
        <a :href="'/' + rootLocation" class="has-padding-15 logoIstmo">
          <img
            width="100"
            :src="rootPath + '/img/' + logo + '_color_nuevo.png'"
            alt="Logo de Istmo Center"
          />
          |
        </a>
        <client-changer
          :client-name="clientName"
          :client-id="clientId"
          @client_change="clientChange"
        ></client-changer>
      </template>
      <template slot="end">
        <b-navbar-item
          v-for="(menuGroup, name, index) in mainMenu"
          :key="index"
          :id="'nav-bar-option-' + index"
          @click="showMenu(index, menuGroup)"
        >
          {{ name }}
        </b-navbar-item>
        <b-navbar-dropdown
          label="Perfil"
          position="is-bottom-left"
          style="z-index: 10"
        >
          <b-navbar-item tag="div" class="is-arrowless">
            <user-avatar :user-name="userName" :src-picture="srcPicture" />
            &nbsp;
            {{ userName }}
          </b-navbar-item>
          <b-navbar-item :href="resetRoute">
            <b-icon icon="key"></b-icon> <span> Cambiar contraseña</span>
          </b-navbar-item>
          <b-navbar-item @click="logout">
            <b-icon icon="sign-out-alt"></b-icon> <span> Cerrar sesión</span>
          </b-navbar-item>
        </b-navbar-dropdown>

        <b-navbar-item @click="onNotificationClick">
          <b-icon icon="bell"></b-icon>
          <notification-counter></notification-counter>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div class="container mainMenuDropdown">
      <div class="menuList">
        <div class="menuItem" v-for="element in actualMenu" :key="element.id">
          <h4>
            <span class="icon">
              <i :class="'fas fa-' + element.icon"></i>
            </span>
            {{ element.label }}
          </h4>
          <ul>
            <li
              v-for="(entry, index) in element.menu"
              :key="index"
              class="itemLink"
            >
              <a @click.prevent.stop="saveVisitLocation(entry)">
                <span class="icon">
                  <i :class="'fas fa-' + entry.icon"></i>
                </span>
                {{ entry.label }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <form
      :action="`${uploads_url}/accounts/login/client/`"
      method="post"
      target="_blank"
      ref="uploadsforms"
    >
      <input type="hidden" name="api_key" :value="api_key" />
      <input ref="mobile" type="hidden" name="mobile" :value="mobile" />
      <input type="hidden" name="username" :value="contacto_id_usuario" />
      <input type="hidden" name="userclient" :value="clientId" />
    </form>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  props: {
    resetRoute: "",
    api: "",
  },
  data() {
    return {
      rootLocation: process.env.MIX_APP_ENV,
      uploads_url: "",
      logo: process.env.MIX_APP_LOGO,
      mainMenuStatus: false,
      mainMenuContainer: HTMLElement,
      blockerContainer: HTMLDivElement,
      currentOpenMenu: Number,
      menuIsOpen: false,
      actualMenu: [],
      mainMenu: [],
      userName: "",
      srcPicture: "",
      clientName: "",
      clientId: 0,
      api_key: "",
      contacto_id_usuario: "",
      mobile: false,
    };
  },
  computed: {
    rootPath() {
      return this.rootLocation.length > 0 ? "/" + this.rootLocation : "";
    },
  },
  created() {
    axios
      .post(this.api)
      .then((response) => {
        this.userName = response.data.userName;
        this.srcPicture = response.data.srcPicture;
        this.mainMenu = response.data.mainMenu;
        this.clientName = response.data.clientName;
        this.clientId = response.data.clientId;
        this.contacto_id_usuario = response.data.contacto_id_usuario;
        this.api_key = response.data.api_key;
        this.uploads_url = response.data.api_uploads_host;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {
    this.mainMenuContainer = document.querySelector(".mainMenuDropdown");
    this.blockerContainer = document.querySelector(".bgDark");
    this.user;
  },
  methods: {
    clientChange(client_id) {
      this.clientId = client_id;
    },
    logout() {
      document.getElementById("logout-form").submit();
    },
    onToggleClick() {
      this.mainMenuStatus = !this.mainMenuStatus;
      this.$eventBus.$emit("toggle-menu");
    },
    onNotificationClick() {
      this.$eventBus.$emit("open-notifications-techie");
    },
    toggleMainMenu() {
      this.openMainMenu = !this.openMainMenu;
    },
    showMenu(index, menuGroup) {
      let selectedItem = document.querySelector("#nav-bar-option-" + index);

      if (this.menuIsOpen) {
        this.actualMenu = menuGroup;
        this.mainMenuContainer.classList.remove("show");
        this.blockerContainer.style.display = "none";
        selectedItem.classList.remove("is-active");
        this.menuIsOpen = false;
        if (this.currentOpenMenu != index) {
          let currentItem = document.querySelector(
            "#nav-bar-option-" + this.currentOpenMenu
          );
          currentItem.classList.remove("is-active");
          this.mainMenuContainer.classList.add("show");
          selectedItem.classList.add("is-active");
          this.blockerContainer.style.display = "block";
          this.blockerContainer.classList.add("show");
          this.menuIsOpen = true;
          this.currentOpenMenu = index;
        } else this.actualMenu = [];
      } else {
        this.actualMenu = menuGroup;
        this.mainMenuContainer.classList.add("show");
        this.blockerContainer.style.display = "block";
        selectedItem.classList.add("is-active");
        this.menuIsOpen = true;
        this.currentOpenMenu = index;
      }
    },
    saveVisitLocation(page) {
      let visitPages = JSON.parse(sessionStorage.getItem("visitPages")) || [];

      let exist = _.find(visitPages, (p) => p.href == page.href);

      let currentLenght = 0;

      if (!exist) currentLenght = visitPages.unshift(page);

      if (currentLenght > 6) visitPages.pop();

      sessionStorage.setItem("visitPages", JSON.stringify(visitPages));
      sessionStorage.removeItem("breadcrumbs");

      if (
        page.href !== "/app/asm/carga_custodia_new" &&
        page.href !== "/app/asm/carga_custodia_new_mobile"
      )
        window.location = this.rootPath + page.href;
      else {
        this.mobile = page.href !== "/app/asm/carga_custodia_new";
        this.$refs.mobile.value = this.mobile;
        this.$refs.uploadsforms.submit();
      }
    },
    hideMenu() {
      let currentItem = document.querySelector(
        "#nav-bar-option-" + this.currentOpenMenu
      );
      this.mainMenuContainer.classList.remove("show");
      this.blockerContainer.style.display = "none";
      currentItem.classList.remove("is-active");
      this.menuIsOpen = false;
      this.actualMenu = [];
    },
  },
};
</script>

<style lang="scss">
#navbar_id {
  .navbar-dropdown {
    z-index: 9;
  }
  .container.mainMenuDropdown.show {
    z-index: 4;
  }
}
</style>
