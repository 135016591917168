/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import vue from 'vue'
window.Vue = vue

import Buefy from 'buefy'

// // require styles
//import 'swiper/dist/css/swiper.css'

Vue.prototype.$eventBus = new Vue();

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import NavBar from './components/panel/NavBar.vue'
Vue.component('nav-bar', NavBar)

import UserAvatar from './components/UserAvatar.vue'
Vue.component('user-avatar', UserAvatar)

import SelectProductType from './components/asm/SelectProductType.vue'
Vue.component('select-product-type', SelectProductType)

import SelectStatusProducts from './components/asm/SelectStatusProducts.vue'
Vue.component('select-status-products', SelectStatusProducts)

import ExcelExporter from './components/ExcelExporter.vue'
Vue.component('excel-exporter', ExcelExporter)

import ClientConsultDeliveredProduct from './components/asm/ClientConsultDeliveredProduct.vue'
Vue.component('client-consult-delivered-product', ClientConsultDeliveredProduct)

import ClientConsultDeliveredProduct1 from './components/asm/ClientConsultDeliveredProduct1.vue'
Vue.component('client-consult-delivered-product1', ClientConsultDeliveredProduct1)

import TableCompletedProducts from './components/asm/TableCompletedProducts.vue'
Vue.component('table-completed-products', TableCompletedProducts)

import TableCompletedProducts1 from './components/asm/TableCompletedProducts1.vue'
Vue.component('table-completed-products1', TableCompletedProducts1)

import TableDetailsClientDocuments from './components/asm/TableDetailsClientDocuments.vue'
Vue.component('table-details-client-documents', TableDetailsClientDocuments)

import RegisterProductEntry from './components/asm/RegisterProductEntry.vue'
Vue.component('register-product-entry', RegisterProductEntry)

import StepsTrackingProduct from './components/asm/StepsTrackingProduct.vue'
Vue.component('steps-tracking-product', StepsTrackingProduct)

import EditDownloadImage from './components/asm/EditDownloadImage.vue'
Vue.component('edit-download-image', EditDownloadImage)

import ValidationAlert from './components/ValidationAlert.vue'
Vue.component('validation-alert', ValidationAlert)

import ArchivoAdjunto from './components/techie/ArchivoAdjunto.vue';
Vue.component('archivo-adjunto', ArchivoAdjunto);

import FileUploader from './components/FileUploader.vue'
Vue.component('file-uploader', FileUploader)

import StartEndDate from './components/StartEndDate.vue'
Vue.component('start-end-date', StartEndDate)

import TableDetailsCancelledProducts from './components/asm/TableDetailsCancelledProducts.vue'
Vue.component('table-details-cancelled-products', TableDetailsCancelledProducts)

import TableCancelledProducts from './components/asm/TableCancelledProducts.vue'
Vue.component('table-cancelled-products', TableCancelledProducts)

import TableProductType from './components/asm/TableProductType.vue'
Vue.component('table-product-type', TableProductType)

import SelectLocations from './components/panel/SelectLocations.vue'
Vue.component('select-locations', SelectLocations)

import SelectLocations1 from './components/panel/SelectLocations1.vue'
Vue.component('select-locations1', SelectLocations1)

import CustomerPersonalInfo from './components/panel/CustomerPersonalInfo.vue'
Vue.component('customer-personal-info', CustomerPersonalInfo)

import AllowThirdPartyDelivery from './components/panel/AllowThirdPartyDelivery.vue'
Vue.component('allow-third-party-delivery', AllowThirdPartyDelivery)

import ReportApplicationFailure from './components/panel/ReportApplicationFailure.vue'
Vue.component('report-application-failure', ReportApplicationFailure)

import Cedula from './components/Cedula.vue'
Vue.component('select-Cedula', Cedula)

import CrudTable from './components/CrudTable.vue'
Vue.component('crud-table', CrudTable)

import EmmSend from './components/sms/EmmSend.vue'
Vue.component('emm-send', EmmSend)

import EmmSendMnt from './components/sms/EmmSendMnt.vue'
Vue.component('emm-send-mnt', EmmSendMnt)

import SelectBasicLocation from './components/SelectBasicLocation'
Vue.component('select-basic-location', SelectBasicLocation)

import ChartDemo from './components/ChartDemo.vue'
Vue.component('chart-demo', ChartDemo)

import DataDisplay from './components/DataDisplay.vue'
Vue.component('data-display', DataDisplay)

import ChartFilter from './components/ChartFilter.vue'
Vue.component('chart-filter', ChartFilter)

import ChartDisplay from './components/ChartDisplay.vue'
Vue.component('chart-display', ChartDisplay)

import GmapsWrapper from './components/GmapsWrapper.vue'
Vue.component('gmaps-wrapper', GmapsWrapper)

import TechieNotifications from './components/techie/TechieNotifications.vue'
Vue.component('techie-notifications', TechieNotifications)

import NotificationCounter from './components/NotificationCounter.vue'
Vue.component('notification-counter', NotificationCounter)

import HomeDashboard from './components/HomeDashboard.vue'
Vue.component('home-dashboard', HomeDashboard)

import PdfExporter from './components/PDFExporter'
Vue.component('pdf-exporter', PdfExporter)

import GeneralStadisticDisplay from './components/GeneralStadisticDisplay.vue'
Vue.component('general-stadistic-display', GeneralStadisticDisplay)

import ChartManager from './components/system/ChartManager.vue'
Vue.component('chart-manager', ChartManager)

import RecentPages from './components/panel/RecentPages.vue'
Vue.component('recent-pages', RecentPages)

import TableTrackingConsult from './components/asm/TableTrackingConsult.vue'
Vue.component('table-tracking-consult', TableTrackingConsult)

import Breadcrumbs from './components/Breadcrumbs.vue'
Vue.component('breadcrumbs', Breadcrumbs)

import GeneralFooter from './components/GeneralFooter.vue'
Vue.component('general-footer', GeneralFooter)

import SwiperGallery from './components/SwiperGallery.vue'
Vue.component('swiper-gallery', SwiperGallery)

import SwiperManager from './components/SwiperManager.vue'
Vue.component('swiper-manager', SwiperManager)

import SwiperTrigger from './components/SwiperTrigger.vue'
Vue.component('swiper-trigger', SwiperTrigger)

import VueMagnifier from './components/VueMagnifier.vue'
Vue.component('vue-magnifier', VueMagnifier)

import TechieExporter from './components/techie/TechieExporter.vue'
Vue.component('techie-exporter', TechieExporter)

import MakePetition from './components/asm/MakePetition.vue'
Vue.component('make-petition', MakePetition)

import MessengerLocation from './components/asm/MessengerLocation.vue'
Vue.component('messenger-location', MessengerLocation)

import MessengerDetails from './components/asm/MessengerDetails.vue'
Vue.component('messenger-details', MessengerDetails)

import TableLiquidationProductInventory from './components/asm/TableLiquidationProductInventory.vue'
Vue.component('table-liquidation-product-inventory', TableLiquidationProductInventory)

import TableLiquidationProductMarked from './components/asm/TableLiquidationProductMarked.vue'
Vue.component('table-liquidation-product-marked', TableLiquidationProductMarked)

import TableCallableProducts from './components/asm/TableCallableProducts.vue'
Vue.component('table-callable-products', TableCallableProducts)

import MassiveProductUpload from './components/asm/MassiveProductUpload.vue'
Vue.component('massive-product-upload', MassiveProductUpload)

import ContactHelp from './components/ContactHelp.vue'
Vue.component('contact-help', ContactHelp)

import TechieAbbottRequestTable from './components/techie/TechieAbbottRequestTable.vue'
Vue.component('techie-abbott-request-table', TechieAbbottRequestTable)

import TableQualityPolls from './components/asm/calidad/TableQualityPolls.vue'
Vue.component('table-quality-polls', TableQualityPolls)

import UploadCreditProducts from './components/asm/UploadCreditProducts.vue'
Vue.component('upload-credit-products', UploadCreditProducts)

import FileUploaderCreditsProductsDocuments from './components/asm/FileUploaderCreditsProductsDocuments.vue'
Vue.component('file-uploader-credits-products-documents', FileUploaderCreditsProductsDocuments)

import ButtonUploadDocuments from './components/ButtonUploadDocuments.vue'
Vue.component('button-upload-documents', ButtonUploadDocuments)

import LoadDocuments from './components/asm/plugins/dpu/LoadDocuments.vue'
import Vue from 'vue';
Vue.component('load-documents', LoadDocuments)

import PackageWeight from './components/asm/PackageWeight'
Vue.component('package-weight', PackageWeight)

import RecentNews from './components/RecentNews'
Vue.component('recent-news', RecentNews)

import ClientChanger from './components/system/ClientChanger'
Vue.component('client-changer', ClientChanger)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.use(Buefy, {
    defaultIconPack: 'fas',
    defaultIconPrev: 'angle-double-left',
    defaultIconNext: 'angle-double-right'
});

const app = new Vue({
    el: '#app',
});
