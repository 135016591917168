<template>
  <div>
    <b-message title="Buscar lista" :closable="false">
      <b-field grouped>
        <b-field expanded label="Lista de envíos" label-position="on-border">
          <b-select
            placeholder="Seleccione un elemento"
            @change.native="onNotificationSelected"
            name="lista_envios"
            expanded
            v-model="idNotification"
          >
            <option
              v-for="notification in notifications"
              :value="notification.id"
              :key="notification.id"
            >
              {{ notification.nombre }}
            </option>
          </b-select>
        </b-field>
        <button
          class="button is-primary has-margin-left-15"
          @click="onNotificationSelected"
        >
          Buscar
        </button>
        <b-button
          tag="a"
          icon-left="plus"
          class="has-margin-left-5"
          type="is-success"
          :href="urlConfig"
          >Agregar</b-button
        >
      </b-field>
    </b-message>
    <b-message title="Envíos" :closable="false">
      <b-table
        :data="notificationsSend"
        :paginated="true"
        :pagination-simple="false"
        :per-page="perPage"
        striped
        pagination-position="bottom"
        default-sort="id_producto"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="vista"
        aria-current-label="Actual"
        class="has-margin-top-10"
      >
        <b-table-column v-slot="props" label="Mensaje" field="mensaje">
          {{ props.row.mensaje }}
        </b-table-column>
        <b-table-column v-slot="props" label="Estado" field="status" sortable>
          {{ props.row.status }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Fecha de envío"
          field="fecha_envio"
          sortable
        >
          {{ new Date(props.row.fecha_envio).toLocaleDateString("es-CR") }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Hora de envío"
          field="hora_envio"
          sortable
        >
          {{
            new Date(
              props.row.fecha_envio + " " + props.row.hora_envio
            ).toLocaleTimeString()
          }}
        </b-table-column>
        <template slot="empty">
          <section class="section">
            <div class="has-text-grey has-text-centered">
              <div>No se encontraron resultados</div>
            </div>
          </section>
        </template>
      </b-table>
    </b-message>
  </div>
</template>

<script>
export default {
  name: "EmmSend",
  props: {
    api: String,
    notifications: Array,
  },
  data() {
    return {
      idNotification: 0,
      urlConfig: "#",
      notificationsSend: [],
      perPage: Number,
    };
  },
  created() {
    this.perPage = Number.parseInt(process.env.MIX_PAGINATE_DEFAULT);
  },
  mounted() {},
  methods: {
    onNotificationSelected() {
      this.urlConfig = "./emm_send_mnt?notificacion_id=" + this.idNotification;
      this.getNotificationsSendList();
    },
    getNotificationsSendList() {
      axios
        .post(this.api, { id: this.idNotification, page: this.page })
        .then((response) => {
          this.notificationsSend = response.data.notificationsSend;
        })
        .catch((error) => {
          this.$buefy.dialog.alert({
            title: "Algo salio mal",
            message: "Favor contacte soporte y narre lo sucedido",
            type: "is-danger",
            icon: "times",
            hasIcon: true,
          });
        });
    },
  },
};
</script>
