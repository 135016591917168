<template>
    <div class="columns">
            <div class="column">
                <canvas id="chart1"></canvas>
            </div>
            <div class="column">
                <canvas id="chart2"></canvas>
            </div>
            <div class="column">
                <canvas id="chart3"></canvas>
            </div>
    </div>
</template>

<script>
//! TODO implementar treeshake, ver migraciones de chart v2 a 3
import Chart from 'chart.js/auto'

    export default {
        name: "ModalEditForm",
        data() {
            return {
                data: {
                    labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
                    datasets: [{
                        label: 'Ventas mensuales',
                        data: [100, 200, 150, 223, 212, 323],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                }
            }
        },
        mounted() {
            let chart1 = document.getElementById('chart1')
            let chart2 = document.getElementById('chart2')
            let chart3 = document.getElementById('chart3')
            let chart_display1 = new Chart(chart1,
            {
                type: 'bar',
                data: this.data,
                options: {
                    scales: {
                        y: {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    },
                    responsive: true,
                    maintainAspecRadio: false,
                }
            })
            let chart_display2 = new Chart(chart2,
            {
                type: 'line',
                data: this.data,
                options: {
                    scales: {
                        y: {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    },
                    responsive: true,
                    maintainAspecRadio: false,
                }
            })
            let chart_display3 = new Chart(chart3,
            {
                type: 'pie',
                data: this.data,
                options: {
                    scales: {
                        y: {
                            ticks: {
                                beginAtZero: true
                            }
                        }
                    },
                    responsive: true,
                    maintainAspecRadio: false,
                }
            })
        }
    }
</script>
