<template>
    <footer>
        <a href="/" class="has-padding-15 logoIstmo">
            <img width="100" :src=" rootPath + '/img/' + logo + '_blanco.png'" alt="Logo de Istmo Center">
        </a>
</footer>
</template>

<script>
    export default {
        name: 'GeneralFooter',
        props: {},
        data() {
            return {
                rootLocation: process.env.MIX_APP_ENV,
                logo: process.env.MIX_APP_LOGO,
            }
        },
        computed: {
            rootPath() {
                return this.rootLocation.length > 0 ? '/' + this.rootLocation : ''
            }
        },
        mounted() {
        },
        methods: {
        }
    }
</script>
