<template>
  <div class="columns">
    <div class="column">
      <section>
        <div class="message-header">
          <p>Mostrado: 20 / {{ products.length }}</p>
        </div>
        <div class="message-body">
          <b-table
            :data="products"
            :paginated="true"
            :pagination-simple="false"
            :per-page="rowsPerPage"
            striped
            pagination-position="bottom"
            default-sort="product_count"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="vista"
            aria-current-label="Actual"
            class="has-margin-top-10"
          >
            <b-table-column
              v-slot="props"
              label="Descripción track proceso"
              field="track_process.descripcion_track_proceso"
              sortable
            >
              <a
                v-on:click.stop.prevent="
                  productDetails(props.row.id_track_proceso)
                "
              >
                {{ props.row.track_process.descripcion_track_proceso }}
              </a>
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Cantidad"
              field="product_count"
              sortable
            >
              <template v-if="props.row.product_count">
                {{ props.row.product_count }}
              </template>
              <template v-else> -- </template>
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="has-text-grey has-text-centered">
                  <div>No se encontraron resultados</div>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </section>
    </div>
    <table-details-cancelled-products
      :details="details"
    ></table-details-cancelled-products>
  </div>
</template>

<script>
export default {
  name: "TableCancelledProducts",
  props: ["products", "values", "api", "method", "page"],
  data() {
    return {
      details: null,
      rowsPerPage: Number,
      request: {},
    };
  },
  created() {
    this.rowsPerPage = Number.parseInt(process.env.MIX_PAGINATE_DEFAULT);
  },
  mounted() {
    if (!Array.isArray(this.values)) this.request = this.values;
  },
  methods: {
    productDetails(id_track_proceso) {
      this.request.id_track_proceso = id_track_proceso;
      this.request.method = this.method;
      this.request.page = this.page;
      this.details = null;

      axios
        .post(this.api, this.request)
        .then((response) => {
          this.details = response.data;
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
