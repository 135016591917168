<template>
  <div>
    <b-table
      :data="products.data"
      :loading="loading"
      paginated
      backend-pagination
      :total="products.total"
      :per-page="perPage"
      @page-change="onPageChange"
      pagination-position="bottom"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"
      striped
      class="has-margin-top-10"
      aria-next-label="Siguiente"
      aria-previous-label="Anterior"
      aria-page-label="Lista"
      aria-current-label="Actual"
      detailed
      detail-key="id_producto"
    >
      <b-table-column
        v-slot="props"
        field="nombre_th"
        label="Nombre completo"
        sortable
      >
        <div
          v-if="
            props.row.nombre_th ||
            props.row.apellido1_th ||
            props.row.apellido2_th
          "
        >
          {{ props.row.nombre_th }} {{ props.row.apellido1_th }}
          {{ props.row.apellido2_th }}
        </div>
        <div v-else>--</div>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="numero_cedula"
        label="Número de cédula"
        sortable
      >
        <template v-if="props.row.numero_cedula">{{
          props.row.numero_cedula
        }}</template>
        <template v-else>--</template>
      </b-table-column>
      <b-table-column v-slot="props" label="Descripción track proceso">
        <template v-if="props.row.status_process">
          {{ props.row.status_process.descripcion_track_proceso }}
        </template>
        <p v-else>Desconocido</p>
      </b-table-column>
      <b-table-column
        v-slot="props"
        field="fh_ultimo_movimiento"
        label="Último movimiento"
      >
        <div v-if="props.row.fh_ultimo_movimiento">
          {{
            new Date(props.row.fh_ultimo_movimiento).toLocaleDateString(
              "es-CR"
            )
          }},
          {{ new Date(props.row.fh_ultimo_movimiento).toLocaleTimeString() }}
        </div>
        <div v-else>--</div>
      </b-table-column>
      <b-table-column v-slot="props" label="Verificación Biométrica">
        <span v-if="props.row.biometrics.length > 0">
          <div class="has-text-success">
            <b-icon icon="fingerprint"></b-icon>
            Verificado
          </div>
        </span>
        <span v-else>
          <div class="has-text-danger">
            <b-icon icon="fingerprint"></b-icon>
            No verificado
          </div>
        </span>
      </b-table-column>
      <b-table-column v-slot="props" label="Trabajado Externo">
        <span v-if="props.row.registry != null">
          <p class="tag">
            {{ props.row.registry.trabajado ? "Si" : "No" }}
          </p>
        </span>
        <p v-else class="is-info">No</p>
      </b-table-column>
      <b-table-column v-slot="props" field="id_producto" label="Ver producto">
        <div>
          <a
            class="no-underline"
            :href="detailsLocation + props.row.id_producto"
          >
            <span class="icon">
              <a class="fas fa-eye is-primary"></a>
            </span>
          </a>
        </div>
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <div class="columns">
          <div class="column is-8">
            <div class="columns is-multiline">
              <!-- <div class="column is-6">
                ID: <strong>{{ props.row.id_producto }}</strong>
              </div> -->
              <div class="column is-6">
                Código de barras:
                <strong v-if="props.row.codigo_barra">{{
                  props.row.codigo_barra
                }}</strong>
                <div v-else>--</div>
              </div>
              <div class="column is-6">
                Solicitud:
                <strong v-if="props.row.descripcion_tarjeta"
                  >{{ props.row.descripcion_tarjeta }} /
                  {{ props.row.consecutivo }}</strong
                >
                <div v-else>--</div>
              </div>
              <div class="column is-6">
                Código especial cliente:
                <strong v-if="props.row.codigo_especial_cliente">{{
                  props.row.codigo_especial_cliente
                }}</strong>
                <div v-else>--</div>
              </div>
              <div class="column is-4">
                Teléfono casa:
                <strong v-if="props.row.telefono_casa">{{
                  props.row.telefono_casa
                }}</strong>
                <div v-else>--</div>
              </div>
              <div class="column is-4">
                Teléfono celular:
                <strong v-if="props.row.telefono_celular">{{
                  props.row.telefono_celular
                }}</strong>
                <div v-else>--</div>
              </div>
              <div class="column is-4">
                Teléfono trabajo:
                <strong v-if="props.row.telefono_trabajo">{{
                  props.row.telefono_trabajo
                }}</strong>
                <div v-else>--</div>
                Ext:<strong>{{
                  props.row.telefono_trabajo_ext != ""
                    ? props.row.telefono_trabajo_ext
                    : "--"
                }}</strong>
              </div>
              <div class="column is-4" v-if="props.row.district">
                Distrito:
                <strong>{{ props.row.district.location_description }}</strong>
              </div>
              <div class="column is-4" v-if="props.row.district && props.row.district.parent">
                Cantón:
                <strong>{{
                  props.row.district.parent.location_description
                }}</strong>
              </div>
              <div class="column is-4" v-if="props.row.district && props.row.district.parent && props.row.district.parent.parent">
                Provincia:
                <strong>{{
                  props.row.district.parent.parent.location_description
                }}</strong>
              </div>
              <div class="column is-12">
                Dirección entrega:
                <strong>{{ props.row.direccion_entrega }}</strong>
              </div>
              <h6 class="column is-12 title is-5">Notas</h6>
              <div class="column is-12">
                <strong v-if="props.row.notas">{{ props.row.notas }}</strong>
                <div v-else>--</div>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="columns is-multiline">
              <div class="column is-12">
                <a
                  v-if="hasTechie"
                  :href="
                    '/app/techie/techie_solicitudes_general_mnt?id_producto=' +
                    props.row.id_producto
                  "
                  class="button is-warning is-fullwidth"
                >
                  <span class="icon">
                    <i class="fas fa-exclamation-circle"></i>
                  </span>
                  <span> Crear caso </span>
                </a>
              </div>
              <div class="column is-12">
                <a
                  class="no-underline button is-fullwidth is-primary"
                  :href="detailsLocation + props.row.id_producto"
                >
                  <span class="icon">
                    <a class="fas fa-eye is-primary"></a>
                  </span>
                  <span> Ver Producto </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="has-text-grey has-text-centered">
            <div>No se encontraron resultados</div>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "TableTrackingConsult",
  props: ["values", "api", "hasTechie", "clientId"],
  data() {
    return {
      products: [],
      loading: false,
      sortField: "id_producto",
      sortOrder: "desc",
      defaultSortOrder: "desc",
      perPage: Number,
      request: new Object(),
      currentPage: 1,
    };
  },
  created() {
    this.perPage = Number.parseInt(process.env.MIX_PAGINATE_DEFAULT);
  },
  mounted() {
    if (!Array.isArray(this.values)) {
      this.request = this.values;
    }
    if (this.request.corte_lote === "all") this.request.lista_cortes_lotes = "";
    this.loadProductsAsync();
  },
  methods: {
    loadProductsAsync() {
      this.request.paginate = this.currentPage;
      this.request.order_by = this.sortField;
      this.request.order_type = this.sortOrder;

      this.loading = true;
      console.log(this.api);

      axios
        .post(this.api, this.request)
        .then((response) => {
          this.loading = false;
          this.products = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          alert("Falló la tabla, si sigue pasando contactenos");
        });
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadProductsAsync();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadProductsAsync();
    },
  },
  computed: {
    detailsLocation() {
      if (this.clientId !== 135)
        return "/app/asm/asm_tracking_consultas_detalles_cliente/";
      else
        return "/app/asm/asm_tracking_consultas_detalles_cliente_completo_autogestion/";
    },
  },
};
</script>
