<template>
    <div class="card">
        <b-loading :is-full-page="false" v-model="loading"></b-loading>
        <header class="card-header">
            <div class="card-header-title"><h4><strong>Estadisticas generales del mes</strong></h4></div>
        </header>
        <div class="card-content" style="height: 350px;">
            <div class="content" style="height: 100%;">
                <canvas id="general-stadistic-chart" style="width: 100%; height: 100%;"></canvas>
            </div>
        </div>
        <footer class="card-footer">
            <a href="/app/asm/asm_general_estadistico" class="card-footer-item">
                <span class="icon">
                    <i class="fas fa-chart-pie"></i>
                </span>
                Ir a Estadística General
            </a>
        </footer>
    </div>
</template>

<script>
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'

    export default {
        name: "GeneralStadisticDisplay",
        props: {
            method: String,
            api: String,
            excelApi: String,
        },
        data() {
            return {
                loading: false,
                reportChartData: Array,
                chartElement: HTMLCanvasElement,
            }
        },
        created () {
        },
        mounted() {
            this.chartElement = document.querySelector('#general-stadistic-chart').getContext('2d')

            this.loading = true
            axios
                .post(this.api, {method: this.method, callTo: 'getGeneralStadistics'})
                .then(response => {
                    this.reportChartData = response.data
                    this.reportChartData.plugins = ChartDataLabels
                    this.reportChartData.options.plugins = {
                        datalabels: {
                            color: '#fff',
                            display: 'auto',
                            clamp: true,
                            anchor: 'top',
                            backgroundColor: function(context) {
                                return context.dataset.backgroundColor;
                            },
                            borderColor: '#fff',
                            borderRadius: 25,
                            borderWidth: 2,
                        }
                    }
                    this.loading = false
                    new Chart(this.chartElement, this.reportChartData)
                })
                .catch(error => {
                    console.log(error)
                    this.loading = false
                })
        },
        methods: {
        }
    }
</script>
