<template>
    <b-button :loading="isExcelLoading" icon-pack="far" icon-left="file-excel" @click="generateExcel" class="is-primary">Exportar a Excel</b-button>
</template>

<script>
import FileSaver from 'file-saver'

    export default {
        name: "ExcelExporter",
        props: ['values', 'apicall',],
        data() {
            return {
                isExcelLoading: false,
                request: new Object(),
            }
        },
        mounted() {
            if (!Array.isArray(this.values))
            {
                this.request = this.values
                delete this.request['_token']
            }

        },
        methods: {
            generateExcel()
            {
                this.isExcelLoading = true

                axios
                    .get(this.apicall, {
                        params: this.request,
                        responseType: 'blob'
                    })
                    .then(response => {
                        let filename
                        this.isExcelLoading = false
                        filename = response.headers["content-disposition"].substring(response.headers["content-disposition"].indexOf('"') + 1, response.headers["content-disposition"].lastIndexOf('"'))

                        let file = new Blob([response.data], {type: response.headers["content-type"]})
                        FileSaver.saveAs(file, filename)
                    })
                    .catch(error=> {
                        console.log(error)
                        this.isExcelLoading = false

                        this.$buefy.dialog.alert({
                            title: 'Falló descarga',
                            message: `Estas tratando de descargar muchos datos, por favor ajuste los filtros para exportar menos datos. Disculpa el inconveniente, ya nuestros técnicos están trabajando para mejorar esta herramienta.`,
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            ariaRole: 'alertdialog',
                            ariaModal: true
                        })

                        // alert("Falló la descarga del Excel, si sigue pasando contacte con el sysadmin")
                    })
            }
        }
    }
</script>
