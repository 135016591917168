<template>
    <div class="ctaFeedBAck buttons">
        <a href="#" @click.prevent="showDetails" >
            <div class="button is-primary is-rounded">
                <i class="fa fa-comment-dots"></i>
                <span>Solicitar ayuda</span>
            </div>
        </a>
    </div>
</template>

<script>
export default {
        name: 'ContactHelp',
        props: {},
        data() {
            return {
            }
        },
        mounted() {
        },
        methods: {
            showDetails() {
                this.$buefy.dialog.confirm({
                    title: 'Solicitud de ayuda',
                    message: `En caso de necesitar ayuda con la aplicación puede contactar :
                        <br><b>Email: </b> <a href="mailto:servicioalcliente@istmocenter.com">servicioalcliente@istmocenter.com</a>
                      
                        <br><b>Teléfono: 2528-7090</b>`,
                    canCancel: ['escape', 'outside'],
                    confirmText: 'Gracias',
                })
            }
        }
    }
</script>
