<template>
  <article class="message">
    <div class="message-header">
      <span>Lista de liquidaciones | Cantidad: {{ products.total }}</span>
      <pdf-exporter
        class="has-margin-right-5"
        :values="parameters"
        :apicall="apiPdf"
      ></pdf-exporter>
      <excel-exporter :values="parameters" :apicall="apiExcel"></excel-exporter>
    </div>
    <div class="message-body">
      <b-table
        :data="products.data"
        :paginated="true"
        :pagination-simple="false"
        :per-page="perPage"
        backend-pagination
        :loading="loading"
        :total="products.total"
        striped
        @page-change="onPageChange"
        pagination-position="bottom"
        default-sort="id_solicitud"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="vista"
        aria-current-label="Actual"
        class="has-margin-top-10"
      >
        <b-table-column v-slot="props" label="Código de barra">
          {{ props.row.product.codigo_barra }}
        </b-table-column>
        <b-table-column v-slot="props" label="Número de cédula">
          {{ props.row.product.numero_cedula }}
        </b-table-column>
        <b-table-column v-slot="props" label="Nombre">
          {{ props.row.product.nombre_th }}
          {{ props.row.product.apellido1_th }}
          {{ props.row.product.apellido2_th }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Fecha de creación"
          field="fh_creacion"
          sortable
        >
          {{ new Date(props.row.fh_creacion).toLocaleDateString("es-CR") }},
          {{ new Date(props.row.fh_creacion).toLocaleTimeString() }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Creado por"
          field="creado_por"
          sortable
        >
          {{ props.row.creado_por }}
        </b-table-column>
        <template slot="empty">
          <section class="section">
            <div class="has-text-grey has-text-centered">
              <div>No se encontraron resultados</div>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </article>
</template>

<script>
export default {
  name: "TableLiquidationProductMarked",
  props: {
    api: String,
    apiPdf: String,
    apiExcel: String,
    parameters: null,
  },
  data() {
    return {
      products: {},
      loading: false,
      isPDFLoading: false,
      isExcelLoading: false,
      perPage: Number,
      request: {},
      currentPage: 1,
    };
  },
  created() {
    this.perPage = Number.parseInt(process.env.MIX_PAGINATE_DEFAULT);

    if (!Array.isArray(this.parameters)) this.request = this.parameters;

    this.loadMarkedProducts();
  },
  mounted() {},
  methods: {
    loadMarkedProducts() {
      this.request.paginate = this.currentPage;

      this.loading = true;

      axios
        .post(this.api, this.request)
        .then((response) => {
          this.loading = false;
          this.products = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          alert("Falló la tabla, si sigue pasando contacte con el sysadmin");
        });
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadMarkedProducts();
    },
  },
};
</script>
