<template>
  <div>
    <b-field label-position="on-border" expanded>
      <b-checkbox name="autoriza" type="is-blue" v-model="autorizaExterno">
          {{ message }}
      </b-checkbox>
    </b-field>
    <b-field grouped>
      <b-field label="Número de cédula" label-position="on-border" expanded>
        <b-input
          name="cedula_autoriza"
          icon="id-card"
          v-model="cedulaAutoriza"
          @input="getPersonName"
          :required="autorizaExterno"
          :disabled="!autorizaExterno"
          validation-message="Este campo es obligatorio"
        ></b-input>
      </b-field>

      <b-field label="Nombre" label-position="on-border" expanded>
        <b-input
          v-model="nombre"
          name="nombre_autoriza"
          icon="user"
          :required="autorizaExterno"
          :disabled="!autorizaExterno"
          validation-message="Este campo es obligatorio"
        ></b-input>
      </b-field>
      <b-field label="Primer apellido" label-position="on-border" expanded>
        <b-input
          v-model="apellido1"
          name="apellido1_autoriza"
          :required="autorizaExterno"
          :disabled="!autorizaExterno"
          validation-message="Este campo es obligatorio"
        ></b-input>
      </b-field>
      <b-field label="Segundo apellido" label-position="on-border" expanded>
        <b-input
          v-model="apellido2"
          name="apellido2_autoriza"
          :required="autorizaExterno"
          :disabled="!autorizaExterno"
          validation-message="Este campo es obligatorio"
        ></b-input>
      </b-field>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "AllowThirdPartyDelivery",
  props: {
      message: {
          type: String,
          default: 'Cuando la unidad le sea devuelta,¿Autoriza a que esta sea recibida por otra persona?',
          required: false,
      }
  },
  data() {
    return {
      cedulaAutoriza: "",
      autorizaExterno: false,
      nombre: "",
      apellido1: "",
      apellido2: "",
    };
  },
  methods: {
    getPersonName() {
      if (this.cedulaAutoriza.length >= 8) {
        axios
          .get("/app/asm/getgometa", { params: { cedula: this.cedulaAutoriza } })

          .then((response) => {
            this.nombre = response.data.firstname;
            this.apellido1 = response.data.lastname1;
            this.apellido2 = response.data.lastname2;
          })
          .catch((err) => {
            console.log("Error: " + err);
          });
      }
    },
  },
};
</script>
