<template>
  <div class="columns">
    <div class="column table-container testClass">
      <b-table
        :data="products.data"
        :loading="loading"
        paginated
        backend-pagination
        :total="products.total"
        :per-page="perPage"
        @page-change="onPageChange"
        pagination-position="bottom"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
        striped
        detailed
        detail-key="id_producto"
        :show-detail-icon="true"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Lista"
        aria-current-label="Actual"
        class="has-margin-top-10"
      >
        <b-table-column
          v-slot="props"
          label="Nombre completo"
          field="nombre_th"
          sortable
        >
          <div
            v-if="
              props.row.nombre_th ||
              props.row.apellido1_th ||
              props.row.apellido2_th
            "
          >
            {{ props.row.nombre_th }} {{ props.row.apellido1_th }}
            {{ props.row.apellido2_th }}
          </div>
          <div v-else>--</div>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="numero_cedula"
          label="Número de cédula"
          sortable
        >
          <template v-if="props.row.numero_cedula">{{
            props.row.numero_cedula
          }}</template>
          <template v-else>--</template>
        </b-table-column>
        <b-table-column v-slot="props" label="Track proceso">
          <template v-if="props.row.status_process.descripcion_track_proceso">{{
            props.row.status_process.descripcion_track_proceso
          }}</template>
          <template v-else>--</template>
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Último movimiento"
          field="fh_ultimo_movimiento"
          sortable
        >
          <div v-if="props.row.fh_ultimo_movimiento">
            {{
              new Date(props.row.fh_ultimo_movimiento).toLocaleDateString(
                "es-CR"
              )
            }},
            {{ new Date(props.row.fh_ultimo_movimiento).toLocaleTimeString() }}
          </div>
          <div v-else>--</div>
        </b-table-column>
        <b-table-column v-slot="props" label="Verificación Biométrica">
          <span v-if="props.row.biometrics.length > 0">
            <div class="has-text-success">
              <b-icon icon="fingerprint"></b-icon>
              Verificado
            </div>
          </span>
          <span v-else>
            <div class="has-text-danger">
              <b-icon icon="fingerprint"></b-icon>
              No verificado
            </div>
          </span>
        </b-table-column>
        <b-table-column v-slot="props" label="Trabajado Externo">
          <span v-if="props.row.registry != null">
            <p>
              {{ props.row.registry.trabajado ? "Si" : "No" }}
            </p>
          </span>
          <p v-else class="is-info">No</p>
        </b-table-column>
        <b-table-column v-slot="props" label="Zona">
          <span v-if="props.row.district != null">
            {{ props.row.district.group2 }}
          </span>
        </b-table-column>
        <b-table-column v-slot="props" field="id_producto" label="Ver llamadas">
          <a :href="productRoute + props.row.id_producto">
            <span class="icon">
              <a class="fas fa-eye is-primary"></a>
            </span>
          </a>
        </b-table-column>
        <template slot="detail" slot-scope="props">
          <div class="columns productDetails">
            <div class="column is-8 details">
              <div class="columns is-multiline listDetails">
                <div class="column is-6">
                  <strong>ID:</strong>{{ props.row.id_producto }}
                </div>
                <div class="column is-6">
                  <strong>Código de barras:</strong>{{ props.row.codigo_barra }}
                </div>
                <div class="column is-6">
                  <strong>Solicitud:</strong
                  >{{ props.row.descripcion_tarjeta }} /
                  {{ props.row.consecutivo }}
                </div>
                <div class="column is-6">
                  <strong>Código especial cliente:</strong
                  >{{ props.row.codigo_especial_cliente }}
                </div>
                <div class="column is-6">
                  <strong>Teléfono casa:</strong>{{ props.row.telefono_casa }}
                </div>
                <div class="column is-6">
                  <strong>Teléfono celular:</strong
                  >{{ props.row.telefono_celular }}
                </div>
                <div class="column is-6">
                  <strong>Teléfono trabajo:</strong
                  >{{ props.row.telefono_trabajo }}
                </div>
                <div class="column is-6">
                  <strong> Ext:</strong
                  >{{
                    props.row.telefono_trabajo_ext != ""
                      ? props.row.telefono_trabajo_ext
                      : "--"
                  }}
                </div>
                <div class="column is-4">
                  <strong>Distrito:</strong
                  >{{ props.row.district.location_description }}
                </div>
                <div class="column is-4">
                  <strong>Cantón:</strong
                  >{{ props.row.district.parent.location_description }}
                </div>
                <div class="column is-4">
                  <strong>Provincia:</strong>
                  {{ props.row.district.parent.parent.location_description }}
                </div>
                <div class="column is-12">
                  <strong>Dirección entrega:</strong>
                  {{ props.row.direccion_entrega }}
                </div>
                <h6 class="column is-12 title is-6">Notas</h6>
                <div class="column is-12">
                  <strong>{{ props.row.notas }}</strong>
                </div>
              </div>
            </div>
            <div class="column is-4 actions">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <h6 class="title is-6">Estado del producto</h6>
                  <template v-if="props.row.registry == null">
                    <register-product-entry
                      class="has-padding-bottom-15"
                      :refresh-when-submit="false"
                      @reload-view="loadCompleteProductsAsync"
                      :idproducto="props.row.id_producto"
                      :api="apiProductRoute"
                    >
                    </register-product-entry>
                  </template>
                  <template v-else>
                    <template
                      v-if="
                        props.row.registry.trabajado ||
                        props.row.registry.trabajado_sin_finalizar ||
                        props.row.registry.mala_formalizacion
                      "
                    >
                      <b-message
                        type="is-success"
                        v-if="
                          props.row.registry.trabajado == 1 &&
                          props.row.registry.trabajado_sin_finalizar != 1
                        "
                        has-icon
                      >
                        <p class="is-size-4">Finalizado</p>
                      </b-message>
                      <b-message
                        type="is-warning"
                        v-if="props.row.registry.trabajado_sin_finalizar == 1"
                        has-icon
                      >
                        <p class="is-size-4">Pendiente de finalizar</p>
                        <register-product-entry
                          class="has-padding-bottom-15"
                          :refresh-when-submit="false"
                          @reload-view="loadCompleteProductsAsync"
                          :idproducto="props.row.id_producto"
                          :api="apiProductRoute"
                        >
                        </register-product-entry>
                      </b-message>
                      <template
                        v-if="props.row.registry.mala_formalizacion == 1"
                      >
                        <b-message
                          type="is-success"
                          v-if="props.row.registry.correccion_revisada"
                          has-icon
                        >
                          <p class="is-size-4">Formalización corregida</p>
                          <p>
                            Comentario:
                            <strong class="has-text-primary">{{
                              props.row.registry.comentario
                            }}</strong>
                          </p>
                          <p>
                            Tipo correción:
                            {{ props.row.registry.tipo_correccion }}
                          </p>
                        </b-message>
                        <b-message v-else type="is-danger">
                          <p class="is-size-4">Mala formalización</p>
                          <p>
                            Tipo de incidente:
                            <strong class="has-text-primary">
                              <span v-if="props.row.registry.incident">{{
                                props.row.registry.incident.tipo_incidente
                              }}</span
                              ><span v-else>No registrado</span></strong
                            >
                          </p>
                          <p>
                            Comentario:
                            <strong class="has-text-primary">{{
                              props.row.registry.comentario
                            }}</strong>
                          </p>
                          <br />
                          <register-product-entry
                            class="has-padding-bottom-15"
                            :refresh-when-submit="false"
                            @reload-view="loadCompleteProductsAsync"
                            :idproducto="props.row.id_producto"
                            :api="apiProductRoute"
                          >
                          </register-product-entry>
                        </b-message>
                      </template>
                    </template>
                    <template v-else>
                      <register-product-entry
                        class="has-padding-bottom-15"
                        :refresh-when-submit="false"
                        @reload-view="loadCompleteProductsAsync"
                        :idproducto="props.row.id_producto"
                        :api="apiProductRoute"
                      >
                      </register-product-entry>
                    </template>
                  </template>
                </div>
                <div class="column is-12">
                  <h6 class="title is-6">Notificaciones</h6>
                  <span v-if="props.row.notification != null">
                    <b-icon icon="mobile" type="is-success" />
                    Se envió un SMS
                  </span>
                  <span v-else-if="props.row.notifables.length > 0">
                    <a
                      :href="
                        '/app/sms_gateway/asm_sms_send_notificacion_producto/' +
                        props.row.id_producto +
                        '?id_tipo_notificacion=' +
                        props.row.notifables[0].id_tipo_notificacion
                      "
                    >
                      <b-icon icon="mobile" />
                      Enviar SMS
                    </a>
                  </span>
                  <span v-else>
                    <b-icon icon="ban" />
                    --
                  </span>
                </div>
                <div class="column is-12 buttons">
                  <h6 class="title is-6">Acciones</h6>
                  <div class="">
                    <a
                      class="is-fullwidth button is-primary"
                      :href="productRoute + props.row.id_producto"
                    >
                      <span class="icon">
                        <i class="fas fa-eye is-primary"></i> Ver llamadas
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="has-text-grey has-text-centered">
              <div>No se encontraron resultados</div>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableCallableProducts",
  props: ["parameters", "api", "sold"],
  data() {
    return {
      rootPage: process.env.MIX_APP_ENV,
      products: [],
      loading: false,
      sortField: "id_producto",
      sortOrder: "desc",
      defaultSortOrder: "desc",
      perPage: Number,
      request: {},
      currentPage: 1,
      isPDFLoading: false,
    };
  },
  computed: {
    productRoute() {
      let url = "/app/asm/asm_tracking_consultas_detalles_gestionado_completo/";
      if (this.sold)
        url = "/app/asm/asm_tracking_consultas_detalles_venta_completo/";

      return url;
    },
    apiProductRoute() {
      let url = "/api/asm/asm_tracking_consultas_detalles_gestionado_completo";
      if (this.sold)
        url = "/api/asm/asm_tracking_consultas_detalles_venta_completo";

      return url;
    },
  },
  created() {
    this.perPage = Number.parseInt(process.env.MIX_PAGINATE_DEFAULT);
  },
  mounted() {
    if (!Array.isArray(this.parameters)) this.request = this.parameters;
    this.loadCompleteProductsAsync();
  },
  methods: {
    loadCompleteProductsAsync() {
      this.request.paginate = this.currentPage;
      this.request.order_by = this.sortField;
      this.request.order_type = this.sortOrder;

      this.loading = true;

      axios
        .post(this.api, this.request)
        .then((response) => {
          this.loading = false;
          this.products = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          alert("Falló la tabla, si sigue pasando contacte con el sysadmin");
        });
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadCompleteProductsAsync();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadCompleteProductsAsync();
    },
  },
};
</script>
