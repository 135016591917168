<template>
  <div>
    <section class="is-dark">
      <div class="message-header">Lista de productos:</div>
      <div class="message-body">
        <b-table
          :data="tracking"
          :paginated="true"
          :pagination-simple="false"
          :per-page="perPage"
          striped
          pagination-position="bottom"
          default-sort-direction="desc"
          default-sort="id_producto"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="vista"
          aria-current-label="Actual"
          detailed
          detail-key="id_producto"
        >
          <b-table-column v-slot="props" label="Emitido por">
            {{ props.row.cliente_nombre }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            label="Número de cédula"
            field="numero_cedula"
            sortable
          >
            {{ props.row.numero_cedula }}
          </b-table-column>
          <b-table-column v-slot="props" label="Nombre">
            {{ props.row.nombre_completo }}
          </b-table-column>
          <b-table-column v-slot="props" label="Código especial cliente">
            Actualizar política:
            {{ props.row.actualiza_politica }} Pre-coordinado Cliente:
            {{ props.row.precoordinado }}
          </b-table-column>
          <b-table-column v-slot="props" label="Esfuerzos">
            Total de esfuerzos: {{ props.row.total_esfuerzos }} Días distinto:
            {{ props.row.esfuerzos_dias_distintos }}
          </b-table-column>
          <b-table-column v-slot="props" label="Descripción track proceso">
            <strong
              ><u>{{ props.row.descripcion_track_proceso }}</u></strong
            >
          </b-table-column>
          <b-table-column v-slot="props" label="Documentos">
            <div v-if="!itHasDocuments(props.row.id_producto)">
              <a
                class="no-underline"
                :href="
                  '/app/asm/plugins/dpu/cliente_externo_carga_documentos/' +
                  props.row.id_producto
                "
              >
                <b-icon icon="folder-plus"></b-icon>
                Agregar
              </a>
            </div>
            <div v-else>Documento cargado</div>
          </b-table-column>
          <template slot="detail" slot-scope="props">
            <div class="columns">
              <div class="column is-8">
                <div class="columns is-multiline">
                  <div class="column is-6">
                    ID: <strong>{{ props.row.id_producto }}</strong>
                  </div>
                  <div class="column is-6">
                    Código de barras:
                    <strong v-if="props.row.codigo_barra">{{
                      props.row.codigo_barra
                    }}</strong>
                    <div v-else>--</div>
                  </div>
                  <div class="column is-8">
                    Cargado el: <strong>{{ props.row.fecha_ingreso }}</strong>
                  </div>
                  <div class="column is-6">
                    Solicitud:
                    <strong v-if="props.row.descripcion_tarjeta"
                      >{{ props.row.descripcion_tarjeta }} /
                      {{ props.row.consecutivo }}</strong
                    >
                    <div v-else>--</div>
                  </div>
                  <div class="column is-6">
                    Código especial cliente:
                    <strong v-if="props.row.codigo_especial_cliente">{{
                      props.row.codigo_especial_cliente
                    }}</strong>
                    <div v-else>--</div>
                  </div>
                  <div class="column is-4">
                    Teléfono casa:
                    <strong v-if="props.row.telefono_casa">{{
                      props.row.telefono_casa
                    }}</strong>
                    <div v-else>--</div>
                  </div>
                  <div class="column is-4">
                    Teléfono celular:
                    <strong v-if="props.row.telefono_celular">{{
                      props.row.telefono_celular
                    }}</strong>
                    <div v-else>--</div>
                  </div>
                  <div class="column is-4">
                    Teléfono trabajo:
                    <strong v-if="props.row.telefono_trabajo">{{
                      props.row.telefono_trabajo
                    }}</strong>
                    <div v-else>--</div>
                    Ext:<strong>{{
                      props.row.telefono_trabajo_ext != ""
                        ? props.row.telefono_trabajo_ext
                        : "--"
                    }}</strong>
                  </div>
                  <div class="column is-4">
                    Distrito: <strong>{{ props.row.distrito }}</strong>
                  </div>
                  <div class="column is-4">
                    Cantón: <strong>{{ props.row.canton }}</strong>
                  </div>
                  <div class="column is-4">
                    Provincia: <strong>{{ props.row.provincia }}</strong>
                  </div>
                  <div class="column is-12">
                    Dirección entrega:
                    <strong>{{ props.row.direccion_entrega }}</strong>
                  </div>
                  <h6 class="column is-12 title is-5">Notas</h6>
                  <div class="column is-12">
                    <strong v-if="props.row.notas">{{
                      props.row.notas
                    }}</strong>
                    <div v-else>--</div>
                  </div>
                </div>
              </div>
              <div
                class="column is-4"
                v-if="itHasDocuments(props.row.id_producto)"
              >
                Documento:
                <div
                  v-for="document in groupedDocuments[props.row.id_producto]"
                  :key="document.id_documento"
                >
                  <a
                    :href="'/app/documents/' + document.url_doc"
                    class="no-underline"
                  >
                    <b-icon icon="eye"></b-icon>
                    <template v-if="document.descripcion_documento_cliente">{{
                      document.descripcion_documento_cliente
                    }}</template>
                    <template v-else>Documento sin descripción</template>
                  </a>
                </div>
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "LoadDocuments",
  props: {
    tracking: {
      type: Array,
    },
    documents: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      perPage: Number,
      groupedDocuments: [],
    };
  },
  created() {
    this.perPage = Number.parseInt(process.env.MIX_PAGINATE_DEFAULT);
  },
  mounted() {
    this.groupedDocuments = this.groupDocuments();
  },
  methods: {
    itHasDocuments(id_producto) {
      let found;
      found = _.find(this.documents, (x) => x.id_producto === id_producto);
      return found !== undefined;
    },
    groupDocuments() {
      return _.groupBy(this.documents, "id_producto");
    },
  },
};
</script>
