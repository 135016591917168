<template>
    <div class="filtersContainer columns is-desktop is-multiline">
        <div class="filter column is-4 has-margin-bottom-50">
            <div class="filterTitle">Zonas:
                <b-button size="is-small" type="is-text" @click="clearZones">Reiniciar</b-button>
            </div>
            <b-field expanded position="is-centered">
                <b-checkbox v-model="zonesSelected" @input="onDataChanged" native-value="METRO" expanded>
                    <div>Metro</div>
                </b-checkbox>
                <b-checkbox v-model="zonesSelected" @input="onDataChanged" native-value="RURAL" expanded>
                    <div>Rural</div>
                </b-checkbox>
              
            </b-field>
        </div>
        <div class="filter column is-4 has-margin-bottom-50">
            <div class="filterTitle">Tipo de inventario:
                <b-button size="is-small" type="is-text" @click="clearInventoryTypes">Reiniciar</b-button>
            </div>
            <b-field expanded position="is-centered">
                <b-checkbox v-model="inventoryTypeSelected" @input="onDataChanged" native-value="Masivo" expanded>
                    <div>Masivo</div>
                </b-checkbox>
                <b-checkbox v-model="inventoryTypeSelected" @input="onDataChanged" native-value="Normal" expanded>
                    <div>Normal</div>
                </b-checkbox>
            </b-field>
        </div>
        <div class="filter column is-4 has-margin-bottom-50 ">
            <div class="filterTitle">Fechas de ingreso a Istmo
                <b-button size="is-small" type="is-text" @click="resetDates">Reiniciar</b-button>
            </div>
            <b-field grouped class="datePicker">
                <b-field label="Desde:" class="">
                    <b-datepicker placeholder="Seleccione una fecha" :max-date="maxDate" :date-formatter="dateFormat"
                        v-model="fromDate" :month-names="monthNames" :day-names="dayNames" icon="calendar-alt"
                        @input="onDataChanged"></b-datepicker>
                </b-field>
                <b-field label="Hasta:" class="">
                    <b-datepicker placeholder="Seleccione una fecha" :max-date="maxDate" :date-formatter="dateFormat"
                        v-model="toDate" :month-names="monthNames" :day-names="dayNames" icon="calendar-day"
                        @input="onDataChanged"></b-datepicker>
                </b-field>
            </b-field>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ChartFilter',
        props: {},
        data() {
            const today = new Date()
            return {
                zonesSelected: ['METRO', 'RURAL'],
                defaultZones: ['METRO', 'RURAL'],
                inventoryTypeSelected: ['Masivo', 'Normal'],
                defaultInventoryType: ['Masivo', 'Normal'],
                fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15),
                defaultFromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15),
                defaultToDate: new Date(),
                toDate: new Date(),
                loading: false,
                dateFormat: (date) => date.toLocaleDateString('es-CR'),
                maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
                monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre",
                    "Octubre", "Noviembre", "Diciembre"
                ],
                dayNames: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sá"]
            }
        },
        mounted() {
            this.onDataChanged()
        },
        methods: {
            ifItemChecked(listOfItems, item) {
                return _.includes(listOfItems, item)
            },
            clearZones() {
                this.zonesSelected = this.defaultZones
                this.onDataChanged()
            },
            clearInventoryTypes() {
                this.inventoryTypeSelected = this.defaultInventoryType
                this.onDataChanged()
            },
            resetDates() {
                this.fromDate = this.defaultFromDate
                this.toDate = this.defaultToDate
                this.onDataChanged()
            },
            onDataChanged() {
                if (this.zonesSelected.length == 0)
                    this.zonesSelected = this.defaultZones

                if (this.inventoryTypeSelected.length == 0)
                    this.inventoryTypeSelected = this.defaultInventoryType

                this.$emit('chart-filter-modified', {
                    zonesSelected: this.zonesSelected,
                    inventoryTypeSelected: this.inventoryTypeSelected,
                    fromDate: this.fromDate,
                    toDate: this.toDate
                })
            }
        }
    }

</script>
