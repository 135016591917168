<template>
    <div>
        <b-message type="is-info">
            Para ver detalles de la entrega, haga click en el nodo
        </b-message>
        <b-steps v-model="currentItem" :has-navigation="false" :animated="false" @input="getTrackData" size="is-large">
            <template v-for="(track, index) in trackingDetails">
                <b-step-item
                    :key="track.id_track_proceso"
                    :label="track.descripcion_track_proceso"
                    :clickable="true"
                    :icon="icons[index]">
                </b-step-item>
            </template>
        </b-steps>
    </div>
</template>
<style lang="scss">
.step-item a
{
    cursor: pointer !important;
}
.step-title
{
    font-size: 1.2rem !important;
}
</style>
<script>
    export default {
        name:'StepsTrackingProduct',
        props: ['trackingDetails', 'currentTrack', 'idproducto', 'api'],
        data() {
            return {
                currentItem: 0,
                lastTrack: 0,
                icons: ['tasks', 'calendar-check', 'shipping-fast', 'people-carry'],
                isLoading: false,
            }
        },
        mounted() {
            this.currentItem = this.trackingDetails.map((x) => x.id_track_proceso).indexOf(this.currentTrack)
            this.lastTrack = this.currentItem
        },
        methods: {
            getTrackData(index)
            {
                if (!this.isLoading) {
                    this.isLoading = true
                    axios
                        .post(this.api, {id_producto: this.idproducto, id_track_proceso: this.trackingDetails[index].id_track_proceso })
                        .then(response => {
                            this.showDetails(response.data, index)
                            this.currentItem = this.lastTrack
                            this.isLoading =false
                        })
                        .catch()
                }
            },
            showDetails(details, index)
            {
                let dialog = ``
                if (!_.isEmpty(details))
                {
                    dialog = `  <div><strong>Fecha: </strong> ${ new Date(details.fecha).toLocaleDateString('es-CR') } ${ new Date(details.fecha).toLocaleTimeString() }</div>
                                <div><strong>Usuario: </strong>${ details.login_usuario }</div>
                                <div><strong>Detalles: </strong>${ details.detalles }</div>
                                <div><strong>Descripción Track Proceso: </strong> ${ this.trackingDetails[index].descripcion_track_proceso }</div>`
                }
                else
                {
                    dialog = `<h1 style="font-size: 1.2em">Paso no realizado</h1>`
                }

                this.$buefy.dialog.alert({
                        title: 'Detalles del proceso',
                        message: dialog,
                        confirmText: 'Aceptar',
                        canCancel: ['escape', 'outside']
                    })
            }
        }
    }
</script>
