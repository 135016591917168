<template>
    <div class="columns">
        <div class="column is-full">
            <!-- <recent-news :fetch-url="''" /> -->
            <h1 class="title is-1">
                Inicio
            </h1>
            <div class="columns">
                <div class="column is-full">
                    <recent-pages :api="api" :call-to="'getRecentPages'"></recent-pages>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <data-display
                        :title="'Entregado | Finalizado'"
                        :type="'is-info'"
                        :icon="'info-circle'"
                        :method="'getDeliveredDocs'"
                        :api="api" />
                </div>
                <div class="column">
                    <data-display
                        :title="'Cancelado'"
                        :type="'is-warning'"
                        :icon="'exclamation-triangle'"
                        :method="'getCanceledProducts'"
                        :api="api" />
                </div>
                <div class="column">
                    <data-display
                        :title="'Malas Formalizaciones'"
                        :type="'is-danger'"
                        :icon="'user-alt-slash'"
                        :method="'getBadFormalizations'"
                        :api="api" />
                </div>
            </div>
            <div class="columns">
                <div class="column is-full">
                    <div class="message is-info">
                        <div class="message-body">
                            Puede dar click en los nombres de los elementos de la gráfica para ocultarlos.
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-full">
                    <general-stadistic-display
                        :method="'getFilteredReport'"
                        :api="api"
                        :excel-api="excelApi"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ChartFilter from './ChartFilter.vue'
    import ChartDisplay from './ChartDisplay.vue'
// import RecentNews from './RecentNews.vue'

    export default {
        name: 'HomeDashboard',
        components: {
            ChartFilter,
            ChartDisplay,
                // RecentNews
        },
        props: {
            api: String,
            excelApi: String,
        },
        data() {
            return {
                chartFilter: {},
                activeItem: null,

            }
        },
        mounted() {},
        methods: {
            updateChartFilter(value) {
                this.chartFilter = value
            },
            selectItem(i) {
                this.activeItem = i;
            }
        }
    }
</script>
