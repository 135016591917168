<template>
<div>
    <div class="user-avatar" v-if="srcPicture" :style="'background-image: url(' + srcPicture + ');'"></div>
    <div class="user-avatar" v-else style="background-color: #054171;">
        <p class="is-capitalized">{{ userNameInitials }}</p>
    </div>
</div>
</template>

<script>
    export default {
        name: 'UserAvatar',
        props: ['userName', 'srcPicture'],
        data() {
            return {
            }
        },
        computed: {
            userNameInitials() {
                let names, initials
                names = this.userName.split(" ")

                 if (names.length > 1)
                    initials = names[0].charAt(0) + " " + names[1].charAt(0)
                else
                    initials = names[0].charAt(0) + " " + names[0].charAt(1)

                return initials
            }
        },
        mounted() {
        }
    }
</script>
