<template>
  <div>
    <div v-if="summary.detail != undefined">
      <div class="columns">
        <b-loading
          :is-full-page="false"
          v-model="isLoading"
          :can-cancel="false"
        ></b-loading>
        <div class="table-container column is-6">
          <section>
            <div class="message-header">
              <p>Resumen</p>
            </div>
            <div class="mesage-body">
              <table class="table is-striped">
                <thead>
                  <tr>
                    <th>Descripción track global</th>
                    <th>Descripción track proceso</th>
                    <th>Conteo</th>
                    <th>Porcentaje</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(entry, i) in summary.detail"
                    :key="i"
                    :class="[
                      {
                        'has-text-weight-bold  has-background-grey-light	':
                          entry.detalle_track_global == 'Subtotal',
                      },
                      {
                        'has-text-weight-bold has-background-primary has-text-white':
                          entry.detalle_track_global == 'Total final',
                      },
                    ]"
                  >
                    <td>{{ entry.detalle_track_global }}</td>
                    <td>
                      <a
                        :href="
                          '?id_track_proceso=' +
                          entry.id_track_proceso +
                          '&?id_corte=' +
                          entry.id_corte +
                          '&?id_tipo_producto=' +
                          entry.id_tipo_producto +
                          '&?id_tipo_subproducto=' +
                          entry.id_tipo_subproducto
                        "
                        v-on:click.stop.prevent="
                          getProcess(
                            entry.id_track_proceso,
                            entry.id_corte,
                            entry.id_tipo_producto,
                            entry.id_tipo_subproducto
                          )
                        "
                        >{{ entry.detalle_track_proceso }}</a
                      >
                    </td>
                    <td>{{ entry.producto_cantidad }}</td>
                    <td>{{ entry.producto_porcentaje }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </div>
        <div class="column table-container is-6">
          <section class="message is-info" v-if="summary.batch != null">
            <div class="message-header">
              <p>Detalles del corte</p>
            </div>
            <div class="message-body">
              <table class="table is-striped">
                <tbody>
                  <tr>
                    <td>Descripción: {{ summary.batch.descripcion }}</td>
                  </tr>
                  <tr>
                    <td>
                      Fecha entrada:
                      {{
                        new Date(
                          summary.batch.fecha_entrada
                        ).toLocaleDateString("es-CR")
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Fecha límite:
                      {{
                        new Date(summary.batch.fecha_limite).toLocaleDateString(
                          "es-CR"
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <div v-if="processTrackData != null" class="table-container">
            <div>
              <section>
                <div class="message-header is-info">
                  <p>Detalles</p>
                  <b-button
                    :loading="isExcelLoading"
                    type="is-primary"
                    icon-pack="far"
                    icon-left="file-excel"
                    v-on:click.stop.prevent="getProductsExcel"
                    >Exportar a Excel</b-button
                  >
                </div>
                <div class="message-body table-container is-narrow">
                  <section>
                    <b-table
                      :data="processTrackData"
                      :paginated="true"
                      :pagination-simple="false"
                      :per-page="15"
                      striped
                      detailed
                      detail-key="id_producto"
                      pagination-position="bottom"
                      default-sort="id_producto"
                      aria-next-label="Siguiente"
                      aria-previous-label="Anterior"
                      aria-page-label="vista"
                      aria-current-label="Actual"
                      class="table-container is-narrow"
                    >
                      <b-table-column v-slot="props" label="Nombre del cliente">
                        <template v-if="props.row.nombre_completo">{{
                          props.row.nombre_completo
                        }}</template>
                        <template v-else>--</template>
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        label="Número de cédula"
                        field="numero_cedula"
                        sortable
                      >
                        <template v-if="props.row.numero_cedula">{{
                          props.row.numero_cedula
                        }}</template>
                        <template v-else>--</template>
                      </b-table-column>
                      <b-table-column
                        v-slot="props"
                        field="id_producto"
                        label="Ver producto"
                        sortable
                      >
                        <a
                          :href="
                            'asm_tracking_consultas_detalles_cliente/' +
                            props.row.id_producto
                          "
                          class="no-underline"
                        >
                          <b-icon icon="eye"></b-icon>
                        </a>
                      </b-table-column>
                      <template slot="detail" slot-scope="props">
                        <div class="columns">
                          <div class="column is-full">
                            <div class="columns is-multiline">
                              <h6 class="column is-12 title is-5">Detalles</h6>
                              <div class="column is-6">
                                ID: <strong>{{ props.row.id_producto }}</strong>
                              </div>
                              <div class="column is-6">
                                Código de barras:
                                <strong v-if="props.row.codigo_barra">{{
                                  props.row.codigo_barra
                                }}</strong>
                                <div v-else>--</div>
                              </div>
                              <div class="column is-6">
                                Solicitud:
                                <strong
                                  >{{ props.row.descripcion_tarjeta }} /
                                  {{ props.row.consecutivo }}</strong
                                >
                              </div>
                              <div class="column is-6">
                                Código especial cliente:
                                <strong
                                  v-if="props.row.codigo_especial_cliente"
                                  >{{
                                    props.row.codigo_especial_cliente
                                  }}</strong
                                >
                              </div>
                              <h6 class="column is-12 title is-5">Contacto</h6>
                              <div class="column is-4">
                                Teléfono casa:
                                <strong v-if="props.row.telefono_casa">{{
                                  props.row.telefono_casa
                                }}</strong>
                                <div v-else>--</div>
                              </div>
                              <div class="column is-4">
                                Teléfono celular:
                                <strong v-if="props.row.telefono_celular">{{
                                  props.row.telefono_celular
                                }}</strong>
                                <div v-else>--</div>
                              </div>
                              <div class="column is-4">
                                Teléfono trabajo:
                                <strong>{{
                                  props.row.telefono_trabajo
                                }}</strong>
                                Ext:<strong>{{
                                  props.row.telefono_trabajo_ext != ""
                                    ? props.row.telefono_trabajo_ext
                                    : "--"
                                }}</strong>
                              </div>
                              <div class="column is-4">
                                Distrito:
                                <strong>{{
                                  props.row.district.location_description
                                }}</strong>
                              </div>
                              <div class="column is-4">
                                Cantón:
                                <strong>{{
                                  props.row.district.parent.location_description
                                }}</strong>
                              </div>
                              <div class="column is-4">
                                Provincia:
                                <strong>
                                  {{
                                    props.row.district.parent.parent
                                      .location_description
                                  }}</strong
                                >
                              </div>
                              <div class="column is-12">
                                Dirección entrega:
                                <strong>
                                  {{ props.row.direccion_entrega }}</strong
                                >
                              </div>
                              <h6 class="column is-12 title is-5">Notas</h6>
                              <div class="column is-12">
                                <strong>{{ props.row.notas }}</strong>
                                <div>
                                  Esfuerzo días distintos:
                                  <strong v-if="props.row.efforts.lenght > 0">{{
                                    props.row.efforts[0].esfuerzos_dias
                                  }}</strong>
                                  <strong v-else>Ninguno</strong>
                                </div>
                                <div>
                                  Fecha de ingreso:
                                  <strong>{{ props.row.fh_ingreso }}</strong>
                                </div>
                                <div>
                                  Fecha de último movimiento:
                                  <strong>{{
                                    props.row.fh_ultimo_movimiento
                                  }}</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template slot="empty">
                        <section class="section">
                          <div class="has-text-grey has-text-centered">
                            <div>No se encontraron resultados</div>
                          </div>
                        </section>
                      </template>
                    </b-table>
                  </section>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <b-notification type="is-warning" role="alert" :closable="false" has-icon>
        No se han encontrado cortes o lotes para dichos productos
      </b-notification>
    </div>
  </div>
</template>

<script>
import FileSaver from "file-saver";
export default {
  name: "TableProductType",
  props: ["summary", "api", "apiExcel", "request"],
  data() {
    return {
      processTrackData: null,
      isLoading: false,
      currentRequest: {
        track_proceso: null,
        corte: null,
        cortes: null,
        tipo_producto: null,
        tipo_subproducto: null,
        page: null,
        method: null,
        fecha_inicio: null,
        fecha_final: null,
      },
      isExcelLoading: false,
      startDate: null,
      endData: null,
    };
  },
  mounted() {
    this.startDate = this.request.fecha_inicio;
    this.endData = this.request.fecha_final;
  },
  methods: {
    getProcess(
      id_track_proceso,
      id_corte,
      id_tipo_producto,
      id_tipo_subproducto
    ) {
      this.processTrackData = null;
      this.isLoading = true;

      this.currentRequest.track_proceso = id_track_proceso;
      this.currentRequest.corte = id_corte;
      this.currentRequest.cortes = this.summary.ids;
      this.currentRequest.subtipos = this.summary.subtipos;
      this.currentRequest.tipo_producto = id_tipo_producto;
      this.currentRequest.subtipo_producto = id_tipo_subproducto;
      this.currentRequest.fecha_inicio = this.startDate;
      this.currentRequest.fecha_final = this.endData;
      axios
        .post(this.api, this.currentRequest)
        .then((response) => {
          this.processTrackData = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getProductsExcel() {
      this.isExcelLoading = true;

      axios
        .get(this.apiExcel, {
          params: this.currentRequest,
          responseType: "blob",
        })
        .then((response) => {
          var filename;
          this.isExcelLoading = false;
          filename = response.headers["content-disposition"].substring(
            response.headers["content-disposition"].indexOf('"') + 1,
            response.headers["content-disposition"].lastIndexOf('"')
          );

          let file = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          FileSaver.saveAs(file, filename);
        })
        .catch((error) => {
          console.log(error);
          this.isExcelLoading = false;
        });
    },
  },
};
</script>
