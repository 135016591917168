<template>
  <div class="columns">
    <div class="column">
      <section>
        <div class="message-header">
          <p>Mostrando: {{ quantity }} / {{ techieList.total }}</p>
          <a :href="createLink" class="button is-primary">
            <b-icon icon="plus" class="mr-1"></b-icon>
            Crear Caso
          </a>
        </div>
        <div class="message-body">
          <b-table
            :data="techieList.data"
            paginated
            :pagination-simple="false"
            :per-page="rowsPerPage"
            striped
            :total="techieList.total"
            backend-pagination
            @page-change="onPageChange"
            :loading.sync="loading"
            pagination-position="bottom"
            default-sort="id_solicitud"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="vista"
            aria-current-label="Actual"
            class="has-margin-top-10"
          >
            <b-table-column
              v-slot="props"
              label="Fecha solicitud"
              field="fecha_solicitud"
            >
              {{
                new Date(props.row.fecha_solicitud).toLocaleDateString("es-CR")
              }}
              {{ new Date(props.row.fecha_solicitud).toLocaleTimeString() }}
            </b-table-column>
            <b-table-column v-slot="props" label="Estado" field="nombre_estado">
              {{ props.row.nombre_estado }}
            </b-table-column>
            <b-table-column v-slot="props" label="Número de cédula">
              <div>{{ props.row.descripcion_corta }}</div>
            </b-table-column>
            <b-table-column v-slot="props" label="Caso #" field="solicitud_id">
              <a
                :href="
                  'techie_solicitudes_externas_mnt/' + props.row.solicitud_id
                "
                class="button is-info"
              >
                <span class="icon"><i class="fas fa-eye"></i></span>
                <span>Caso # {{ props.row.solicitud_id }}</span>
              </a>
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Comunicaciones"
              field="cantidad_comunicaciones"
              sortable
            >
              <a
                :href="
                  'techie_clientes_comunicaciones/' + props.row.solicitud_id
                "
                class="button is-danger"
              >
                <span class="icon"><i class="fas fa-eye"></i></span>
                <span
                  >Comunicaciones ({{
                    props.row.cantidad_comunicaciones
                  }})</span
                >
              </a>
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="has-text-grey has-text-centered">
                  <div>No se encontraron resultados</div>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "TechieAbbottRequestTable",
  props: {
    api: String,
    parameters: {},
    createLink: "",
  },
  data() {
    return {
      rowsPerPage: 0,
      request: {},
      techieList: {},
      loading: false,
      currentPage: 1,
    };
  },
  created() {
    this.rowsPerPage = Number.parseInt(process.env.MIX_PAGINATE_DEFAULT);
  },
  mounted() {
    if (!Array.isArray(this.parameters)) this.request = this.parameters;

    this.loadTechieList();
  },
  computed: {
    quantity() {
      let quantity = 0;

      if (!_.isEmpty(this.techieList)) quantity = this.techieList.data.length;

      return quantity;
    },
  },
  methods: {
    loadTechieList() {
      this.request.paginate = this.currentPage;
      this.loading = true;

      axios
        .post(this.api, this.request)
        .then((response) => {
          this.techieList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          alert("Falló la tabla, si sigue pasando contacte con el sysadmin");
        });
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadTechieList();
    },
  },
};
</script>
