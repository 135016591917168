<template>
    <div>
        <b-notification
            type="is-danger"
            has-icon
            aria-close-label="Cerrar notificación"
            role="alert">
            <p>Los siguientes campos presentan los siguientes errores, por favor corríjalos:</p>
            <ul>
                <li v-for="(field, i) in fields" :key="i">
                    {{ field }}
                </li>
            </ul>
        </b-notification>
    </div>
</template>

<script>
    export default {
        name: 'ValidationAlert',
        props: ['fields'],
        mounted() {
        }
    }
</script>
