<template>
    <div>
        <img :src="imgUrl" :alt="imgDescription" width="96" height="54" @click="active">
    </div>
</template>

<script>
    export default {
        name: 'SwiperTrigger',
        props: {
            detailDocuments: Array,
            imgUrl: String,
            imgDescription: String,
            oldDocument: {
                default: false,
                type: Boolean,
            }
        },
        data() {
            return {
                img_path: process.env.MIX_IMG_PATH
            }
        },
        mounted() {
        },
        methods: {
            active()
            {
                let images = []

                _.forEach(this.detailDocuments, entry =>
                {
                    let img = new Image(), imgOrientation = ''

                    img.src = this.img_path + entry.url_doc

                    imgOrientation = img.height > img.width ? 'vertical' : 'horizontal'

                    images.push({ imgUrl: this.img_path + entry.url_doc, imgDescription: entry.descripcion_documento_cliente, imgOrientation: imgOrientation, oldDocument: this.oldDocument })
                })

                this.$eventBus.$emit('active-gallery', images)
            }
        }
    }
</script>
