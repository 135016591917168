<template>
    <div ref="gmapContainer" class="notification" style="width: 100%; height: 40%; padding: 0;">
        <GmapMap
            :center="{ lat: center.lat, lng: center.lng }"
            :zoom="zoom"
            map-type-id="roadmap"
            style="width: 100%; height: 100%;"
            :options="{
                streetViewControl: false,
                styles: styles,
                mapTypeControl: false,
                rotateControl: false,
            }"
            ref="messengerMap">
            <GmapInfoWindow :options="infoOptions" :position="infoWindow.position" :opened="infoWindow.isOpen" @closeclick="infoWindow.isOpen = false" />
            <GmapMarker
                v-for="(messenger, i) in messengers"
                :key="i"
                :position="messenger.coordinates"
                :clickable="true"
                :draggable="false"
                :icon="messenger.icon"
                @click="toggleInfoMessage(messenger, i)"
            />
        </GmapMap>
        <b-loading :is-full-page="false" :active="isDataLoading"></b-loading>
    </div>
</template>

<script>
import * as GoogleMaps from 'vue2-google-maps'
import vue from 'vue'

vue.use(GoogleMaps, {
    load: {
        key: 'AIzaSyBQH4ppr3zGrh5AE_Ib3Sth6rqadUUW4xs',
        libraries: 'places',
    },
})

    export default {
        name: 'MessengerLocation',
        props: {
            messengersIds: Array,
            project: '',
            api: '',
        },
        data() {
            return {
                isDataLoading : false,
                messengers: [],
                iconsLocation: '/img/maps/icons/',
                center: { lat: 9.866614, lng: -83.921963 },
                zoom: 8,
                infoWindow: { position: null, isOpen: false, currentMessengerId: null },
                infoOptions: {
                    content: '',
                    // offset opcional para mejor posicion
                    pixelOffset: {
                        width: 0,
                        height: -35
                    }
                },
                icons:{
                    'Posición actual': 'Moto.png',
                    'Acceso o Salida': 'accesoSalida.png',
                    'Esfuerzo registrado': 'esfuerzoRegistrado.png',
                    'GPS apagado': 'gpsOff.png',
                    'Incidente': 'incidente.png',
                    'Reporte Posición': 'Moto.png',
                    'Producto devuelto': 'pDevuelto.png',
                    'Producto Reprogramado': 'repro.png',
                    'Producto Reprogramado C2A': 'reproC2A.png',
                    'Producto Reprogramado C2B': 'reproC2B.png',
                    'Formalizador': 'Moto.png',
                    'Producto para reintentar': 'pReintentar.png',
                    'Rechazado': 'rechazdo.png',
                    'Abandona la formalización': 'abandonaFormalizacion.png',
                },
                styles: [{
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#f1cc4e"
                            }]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#523735"
                            }]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [{
                                "color": "#f5f1e6"
                            }]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.stroke",
                            "stylers": [{
                                "color": "#c9b2a6"
                            }]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "administrative.land_parcel",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#ae9e90"
                            }]
                        },
                        {
                            "featureType": "administrative.neighborhood",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "landscape.man_made",
                            "elementType": "geometry.fill",
                            "stylers": [{
                                "color": "#ead684"
                            }]
                        },
                        {
                            "featureType": "landscape.natural",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#eed77b"
                            }]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#d8bf69"
                            }]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#93817c"
                            }]
                        },
                        {
                            "featureType": "poi.business",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#447530"
                            }]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#f5f1e6"
                            }]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels.icon",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "road.highway.controlled_access",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#e98d58"
                            }]
                        },
                        {
                            "featureType": "road.highway.controlled_access",
                            "elementType": "geometry.stroke",
                            "stylers": [{
                                "color": "#db8555"
                            }]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#806b63"
                            }]
                        },
                        {
                            "featureType": "transit",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#dfd2ae"
                            }]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#8f7d77"
                            }]
                        },
                        {
                            "featureType": "transit.line",
                            "elementType": "labels.text.stroke",
                            "stylers": [{
                                "color": "#ebe3cd"
                            }]
                        },
                        {
                            "featureType": "transit.station",
                            "elementType": "geometry",
                            "stylers": [{
                                "color": "#dfd2ae"
                            }]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry.fill",
                            "stylers": [{
                                "color": "#b9d3c2"
                            }]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "water",
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                "color": "#92998d"
                            }]
                        }],
            }
        },
        mounted() {
            this.getMessengersLocations()
            setInterval(this.getMessengersLocations, 180000)
        },
        methods: {
            getMessengersLocations() {
                this.isDataLoading = true
                axios
                    .post(this.api, { ids: this.messengersIds, project: this.project })
                    .then(response => {
                        let messengersRaw = response.data
                        this.isDataLoading = false
                        _.forEach(messengersRaw, (val, key) => {

                            let lastReportDate = new Date(val.fecha_hora_servidor), now = Date.now()

                            let minutesSinceLastReport = Math.abs(lastReportDate - now)

                            let icon = 'Moto.png'

                            if (minutesSinceLastReport > 180000)
                                icon = 'gpsOff.png'

                            let messenger = {
                                icon: this.iconsLocation + icon,
                                coordinates: { lat: Number.parseFloat(val.latitud), lng: Number.parseFloat(val.longitud) },
                                info: `<div class="card">
                                        <div class="card-content">
                                            <div class="media">
                                                <div class="media-left">
                                                    <figure class="image is-128x128 img-thumbnail rounded-circle img-person"><img src="${ process.env.MIX_IMG_PERSONAL_INTERNO + val.messenger.person.person_photo}" alt="Foto de ${val.messenger.nombre_empleado_reportes}" class="is-rounded"></figure>
                                                </div>
                                                <div class="media-content">
                                                    <div class="title is-4">
                                                        ${val.messenger.nombre_empleado_reportes}
                                                    </div>
                                                    <div>
                                                        <div class="subtitle is-6">ID: <strong>${val.pk_empleado}</strong></div>
                                                        <div class="subtitle is-6">Zona: <strong>${val.messenger.zona_mensajero}</strong></div>
                                                        <div class="subtitle is-6">Fecha de último reporte: <strong>${val.fecha_hora_servidor}</strong></div>
                                                        <div class="subtitle is-6">Fecha de última acción: <strong>${val.fecha_hora}</strong></div>
                                                        <div class="subtitle is-6">Última acción: <strong>${val.accion}</strong></div>
                                                        <div class="subtitle is-7"><a class="has-text-primary" style="text-decoration: none;" href="/app/asm/maps/map_details/${val.pk_empleado}">Más detalles</a></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       </div>`,
                                nombre: val.messenger.nombre_empleado_reportes
                            }
                            this.messengers.push(messenger)
                        })
                    })
                    .catch(error => {
                        console.log(error)
                        this.isDataLoading = false
                    })
            },
            toggleInfoMessage(messenger, index) {
                this.infoWindow.position = messenger.coordinates
                this.infoOptions.content = messenger.info

                if (this.infoWindow.currentMessengerId === index)
                    this.infoWindow.isOpen = !this.infoWindow.isOpen
                else
                {
                    this.infoWindow.isOpen = true
                    this.infoWindow.currentMessengerId = index
                }
            }
        }
    }
</script>
