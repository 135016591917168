<template>
  <div>
    <b-field grouped>
      <b-field label="Número de cédula" label-position="on-border" expanded>
        <b-input
          name="numero_cedula"
          icon="id-card"
          v-model="cedulaPersona"
          @input="getPersonData"
          required
          validation-message="Este campo es obligatorio"
        ></b-input>
      </b-field>
      <b-field label="Nombre" label-position="on-border" expanded>
        <b-input
          v-model="nombre"
          name="nombre_cliente"
          icon="user"
          required
          validation-message="Este campo es obligatorio"
        ></b-input>
      </b-field>
      <b-field label="Primer apellido" label-position="on-border" expanded>
        <b-input
          v-model="apellido1"
          name="primer_apellido_cliente"
          required
          validation-message="Este campo es obligatorio"
        ></b-input>
      </b-field>
      <b-field label="Segundo apellido" label-position="on-border" expanded>
        <b-input
          v-model="apellido2"
          name="segundo_apellido_cliente"
          required
          validation-message="Este campo es obligatorio"
        ></b-input>
      </b-field>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "CustomerPersonalInfo",
  props: [],
  data() {
    return {
      nombre: null,
      apellido1: null,
      apellido2: null,
      cedulaPersona: null,
    };
  },
  methods: {
    getPersonData() {
      if (this.cedulaPersona.length >= 8) {
        axios
          .get("/app/asm/getgometa", { params: { cedula: this.cedulaPersona } })

          .then((response) => {
            this.nombre = response.data.firstname;
            this.apellido1 = response.data.lastname1;
            this.apellido2 = response.data.lastname2;
          })
          .catch((err) => {
            console.log("Error: " + err);
          });
      }
    },
  },
};
</script>
