<template>
  <article class="message">
    <div class="message-header">
      <span>Inventario - Producto restante: {{ products.total }} </span>
      <button class="button" @click="loadInventory">
        <span class="icon">
          <i class="fas fa-sync"></i>
        </span>
        <span>Actualizar</span>
      </button>
    </div>
    <div class="message-body">
      <b-table
        :data="products.data"
        :paginated="true"
        backend-pagination
        :total="products.total"
        @page-change="onPageChange"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
        :pagination-simple="false"
        :per-page="perPage"
        :loading="loading"
        striped
        pagination-position="bottom"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="vista"
        aria-current-label="Actual"
        class="has-margin-top-10"
      >
        <b-table-column
          v-slot="props"
          label="Código de barra"
          field="codigo_barra"
          sortable
          numeric
        >
          {{ props.row.codigo_barra }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Número de cédula"
          field="numero_cedula"
          sortable
        >
          {{ props.row.numero_cedula }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Nombre"
          field="nombre_th"
          sortable
        >
          {{ props.row.nombre_th }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Primer apellido"
          field="apellido1_th"
          sortable
        >
          {{ props.row.apellido1_th }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          label="Segundo apellido"
          field="apellido2_th"
          sortable
        >
          {{ props.row.apellido2_th }}
        </b-table-column>
        <template slot="empty">
          <section class="section">
            <div class="has-text-grey has-text-centered">
              <div>No se encontraron resultados</div>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </article>
</template>

<script>
export default {
  name: "TableLiquidationProductInventory",
  props: {
    api: String,
    parameters: null,
  },
  data() {
    return {
      products: {},
      loading: false,
      sortField: "codigo_barra",
      sortOrder: "desc",
      defaultSortOrder: "desc",
      perPage: Number,
      request: {},
      currentPage: 1,
    };
  },
  created() {
    this.perPage = Number.parseInt(process.env.MIX_PAGINATE_DEFAULT);

    if (!Array.isArray(this.parameters)) this.request = this.parameters;

    this.loadInventory();
  },
  mounted() {},
  methods: {
    loadInventory() {
      this.request.paginate = this.currentPage;
      this.request.order_by = this.sortField;
      this.request.order_type = this.sortOrder;

      this.loading = true;

      axios
        .post(this.api, this.request)
        .then((response) => {
          this.loading = false;
          this.products = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          alert("Falló la tabla, si sigue pasando contacte con el sysadmin");
        });
    },
    onPageChange(page) {
      this.currentPage = page;
      this.loadInventory();
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadInventory();
    },
  },
};
</script>
