<template>
    <div class="columns">
        <div class="column is-full">
            <div class="columns">
                <div class="column is-full">
                    <chart-filter @chart-filter-modified="updateChartFilter"></chart-filter>
                </div>
            </div>
            <div class="columns">
                <div class="column is-full">
                    <div class="message is-info">
                        <div class="message-body">
                            Puede dar click en los nombres de los elementos de la gráfica para ocultarlos.
                            <strong>Estos filtros rápidos no se aplican al exportar a Excel</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column is-4">
                    <chart-display :chart-id="'pie-chart-zones'" :chart-type="'pie'"
                        :chart-title="'Distribución de inventario por zona'" :method="'byZone'" :api="api"
                        :excel-api="excelApi" :show-details="true" :chart-filter-parameters="chartFilter" />
                </div>
                <div class="column is-4">
                    <chart-display :chart-id="'donut-chart-completed-track'" :chart-type="'doughnut'"
                        :chart-title="'Distribución de completos'" :method="'byCompletedStatus'" :api="api"
                        :excel-api="excelApi" :show-details="true" :chart-filter-parameters="chartFilter" />
                </div>
                <div class="column is-4">
                    <chart-display :chart-id="'donut-chart-waiting-track'" :chart-type="'doughnut'"
                        :chart-title="'Distribución de pendiente'" :method="'byWaitingStatus'" :api="api"
                        :excel-api="excelApi" :show-details="true" :chart-filter-parameters="chartFilter" />
                </div>
            </div>
            <div class="columns is-multiline">
                <div class="column is-6">
                    <chart-display :chart-id="'line-chart-ingress-product'" :chart-type="'line'"
                        :chart-title="'Ingreso diario de Producto'" :method="'byDailyIngress'" :api="api"
                        :excel-api="excelApi" :show-details="true" :chart-filter-parameters="chartFilter" :reload-on-change="false" />
                </div>
                <div class="column is-6">
                    <chart-display :chart-id="'line-chart-ingress-monthly'" :chart-type="'line'"
                        :chart-title="'Entregas mensuales de producto'" :method="'byMonthlyDelivery'" :api="api"
                        :excel-api="excelApi" :show-details="true" :chart-filter-parameters="chartFilter" :reload-on-change="false" />
                </div>

            </div>
            <div class="columns">
                <div class="column is-4">
                    <chart-display :chart-id="'donut-chart-inventory-type'" :chart-type="'doughnut'"
                        :chart-title="'Distribución de inventario por tipo'" :method="'byInventoryType'" :api="api"
                        :excel-api="excelApi" :show-details="true" :chart-filter-parameters="chartFilter" />
                </div>
                <div class="column is-4">
                    <chart-display :chart-id="'bar-chart-month-ingress'" :chart-type="'bar'"
                        :chart-title="'Ingreso mensual de producto'" :method="'byMonthlyIngress'" :api="api"
                        :excel-api="excelApi" :display-legend="false" :chart-filter-parameters="chartFilter" />
                </div>
                <div class="column is-4">
                    <chart-display :chart-id="'donut-chart-global-track'" :chart-type="'doughnut'"
                        :chart-title="'Distribución de track global'" :method="'byGlobalTrack'" :api="api"
                        :excel-api="excelApi" :show-details="true" :chart-filter-parameters="chartFilter" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ChartFilter from '../ChartFilter.vue'
    import ChartDisplay from '../ChartDisplay.vue'

    export default {
        name: 'ChartManager',
        components: {
            ChartFilter,
            ChartDisplay
        },
        props: {
            api: String,
            excelApi: String,
        },
        option: {

        },
        data() {
            return {
                chartFilter: {},
            }
        },
        mounted() {},
        methods: {
            updateChartFilter(value) {
                this.chartFilter = value
            }
        }
    }

</script>
