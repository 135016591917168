<template>
    <div class="notification-counter" v-if="quantity > 0">
        {{ quantity }}
    </div>
</template>

<script>
    export default {
        name: 'NotificationCounter',
        props: {
        },
        data() {
            return {
                quantity: Number
            }
        },
        created(){
            this.$eventBus.$on('show-counter', (q) => {
                this.quantity = q
            })
        },
        mounted() {
        },
        methods: {
        }
    }
</script>
