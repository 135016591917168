<template>
    <div>
        <div v-for="(newsSection, i) in news" :key="i">
            <b-message has-icon :type="newsSection.messageType === 'danger' ? 'is-danger' : 'is-warning'">
                <p class="message-text" v-html="newsSection.messageContent">
                </p>
                <button v-if="newsSection.isClosable" class="delete" @click="removeNews(i)"></button>
            </b-message>
        </div>
    </div>
</template>

<script>
export default {
    name: "RecentNews",
    props: {
        fetchUrl: "",
    },
    data() {
        return {
            news: [
                {
                    messageType: 'info',
                    messageContent: `Se les informa que del 01 al 14 de febrero, se tendrá un cronograma especial para las zonas de Perez Zeledon y parte de la zona Sur<br>
                                    <strong>MARTES 01:</strong> Cajón, San Pedro, Platanares y Amistad / <strong>MIÉRCOLES 02:</strong> Páramo-San Ramón Sur y Rio Nuevo-Santa Rosa, Centro y alrededores / <strong>JUEVES 03:</strong> Daniel Flores y Osa / <strong>VIERNES 04:</strong> Cajón, San Pedro, Pejibaye, Colinas de Buenos Aires. / <strong>LUNES 07:</strong> Rivas, Centro, Daniel Flores, Barú, Rosario de Pacuar, Morete, Cocori, la Palma , el Roble, las esperanzas, la Ceniza, Ceibo, Ojo de agua, Pacuarito / <strong>MARTES 08:</strong> Cajón, San Pedro, Platanares y Amistad / <strong>MIÉRCOLES 09:</strong> Páramo-San Ramón Sur y Rio Nuevo-Santa Rosa, Centro y alrededores / <strong>JUEVES 10:</strong> OSA, Daniel Flores / <strong>VIERNES 11:</strong> Cajón, San Pedro, Pejibaye, Colinas de Buenos Aires / <strong>SÁBADO 12:</strong> Barú, Rosario de Pacuar, Morete, Cocori, la Palma , el Roble, las esperanzas, la Ceniza, Ceibo, Ojo de agua, Pacuarito.`,
                    isClosable: true,

                }
            ],
        }
    },
    mounted() {
        this.getLastestNews()
    },
    methods: {
        getLastestNews() {

        },
        removeNews(index) {
            this.news.splice(index, 1)
        }
    }
}
</script>

<style scoped>
    .message-text {
        padding-top: 5px;
    }

    .delete {
        position: absolute;
        top: 0;
        right: 0;
    }

    /* i {
        color: #f9c900;
    } */
</style>
