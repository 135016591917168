<template>
    <div>
        <b-field grouped>
            <div :class="['file', 'has-name', 'is-fullwidth']">
                <label class="file-label">
                    <input ref="archivoAdjunto" type="file" class="file-input archivo_adjunto" :required="required" name="archivo_adjunto" @change="nombreArchivoAdjunto" :accept="accept">
                    <span class="file-cta" :style="`background-color: ${myCustomColor}; color:black;`">
                        <span class="file-icon">
                            <i class="fas fa-upload"></i>
                        </span>
                        <span class="file-label">
                            Adjuntar archivo
                        </span>
                    </span>
                    <span class="file-name">
                        <p v-if="!filenameAdjunto">Seleccione un archivo</p>
                        <p v-else>{{ filenameAdjunto }}</p>
                    </span>
                </label>
            </div>
            <b-field>
                <button type="button" v-if="filenameAdjunto" @click="resetArchivoAdjunto" class="button is-success has-top-margin-20" style="background-color: #eee; color: black;">
                    <i class="far fa-trash-alt"></i>
                </button>
            </b-field>
        </b-field>
    </div>
</template>

<script>
export default {
    name: 'ArchivoAdjunto', 
    props: {
        accept: {
            default: '.pdf,application/pdf,image/*',
            type: String
        },
        required: {
            default: false,
            type: Boolean
        },
        customColor: {
            default: '#eee !important',
            type: String
        },
    },
    data() {
        return {
            filenameAdjunto: null,
            myCustomColor: '#eee !important',
        }
    },
    methods: {
        nombreArchivoAdjunto(e) {
            this.filenameAdjunto = e.target.files[0]?.name;
        },
        resetArchivoAdjunto() {
            this.$refs.archivoAdjunto.value = null;
            this.filenameAdjunto = null;
        }
    },
    mounted() {
        console.log('ArchivoAdjunto.vue montado correctamente');
    },
    watch: {
        filenameAdjunto: function () {
            this.myCustomColor = this.filenameAdjunto ? '#eee !important' : '#eee !important';
        }
    }
}
</script>
