<template>
    <section>
        <b-dropdown trap-focus position="is-bottom-left">
            <button class="button is-primary" slot="trigger" slot-scope="{ active }">
                <span class="icon">
                    <i class="fas fa-file-excel"></i>
                </span>
                <span>Exportar</span>
                <b-icon :icon="active ? 'caret-up' : 'caret-down'"></b-icon>
            </button>
            <b-dropdown-item
                :focusable="false"
                custom
                paddingless>
                <div class="modal-card" style="width: auto">
                    <section class="modal-card-body">
                        <div class="has-margin-15">
                            <button :class="{ 'is-loading' : isLoadingAll }" class="button is-fullwidth" @click="allCases">
                                <span>Todos los casos</span>
                            </button>
                        </div>
                        <div class="has-margin-15">
                            <button :class="{ 'is-loading' : isLoadingOpen }" class="button is-fullwidth" @click="activeOnly">
                                <span>Solo activos</span>
                            </button>
                        </div>
                        <div class="has-margin-15">
                            <button :class="{ 'is-loading' : isLoadingClose }" class="button is-fullwidth" @click="closedOnly">
                                <span>Casos cerrados</span>
                            </button>
                        </div>
                    </section>
                </div>
            </b-dropdown-item>
        </b-dropdown>
    </section>
</template>

<script>
import FileSaver from 'file-saver'

    export default {
        name: 'TechieExporter',
        props: {
            api: String,
        },
        data() {
            return {
                open: false,
                close: false,
                isLoadingAll: false,
                isLoadingOpen: false,
                isLoadingClose: false,
            }
        },
        mounted() {
        },
        methods: {
            allCases() {
                this.isLoadingAll = true
                this.open = false
                this.close = false
                this.makeRequest()
            },
            activeOnly() {
                this.isLoadingOpen = true
                this.open = true
                this.close = false
                this.makeRequest()
            },
            closedOnly() {
                this.isLoadingClose = true
                this.close = true
                this.open = false
                this.makeRequest()
            },
            makeRequest() {
                axios
                    .get(this.api, {
                        params:
                        {
                            open: this.open,
                            close: this.close,
                        },
                        responseType: 'blob'})
                    .then(response => {
                        let filename
                        this.isExcelLoading = false
                        filename = response.headers["content-disposition"].substring(response.headers["content-disposition"].indexOf('"') + 1, response.headers["content-disposition"].lastIndexOf('"'))

                        let file = new Blob([response.data], {type: response.headers["content-type"]})
                        FileSaver.saveAs(file, filename)
                    })
                    .catch(error => {
                        console.log(error)
                    })
                    .then(() => {
                        this.isLoadingAll = false
                        this.isLoadingOpen = false
                        this.isLoadingClose = false
                    })
            }
        }
    }
</script>
