<template>
    <div :class="['file', 'has-name', 'is-fullwidth', buttontype]">
        <label class="file-label">
            <input type="file" class="file-input archivo_cargar" :required="required" :name="archivoNombre" @change="nombreArchivo" :accept="accept">
            <span class="file-cta">
                <span class="file-icon">
                    <i class="fas fa-upload"></i>
                </span>
                <span class="file-label">
                    Subir archivo
                </span>
            </span>
            <span class="file-name">
                <p v-if="!filename">Seleccione un archivo</p>
                <p v-else>{{ filename }}</p>
            </span>
        </label>
    </div>
</template>

<script>
    export default {
        name: 'FileUploader',
        props: {
            buttontype: {
                default: 'is-info',
                type: String
            },
            accept: {
                default: '.pdf,application/pdf,image/*',
                type: String
            },
            required: {
                default: false,
                type: Boolean
            },
            data:{
            }
        },
        data() {
            return {
                filename: null,
                archivoNombre: 'archivo'
            }
        },
        mounted() {
            if (_.has(this.data, 'index')) {
                this.archivoNombre = 'archivo'+this.data.index
            }
        },
        methods: {
            nombreArchivo(e)
            {
                this.filename = e.srcElement.files[0].name
            }
        }
    }
</script>
