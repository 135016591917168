<template>
  <div class="column is-two-thirds">
    <template v-if="details">
      <section>
        <div class="message-header">
          <p>Detalles</p>
        </div>
        <div class="">
          <b-table
            :data="details"
            :paginated="true"
            :pagination-simple="false"
            :per-page="perPage"
            striped
            :narrowed="true"
            detailed
            detail-key="id_cancelacion"
            pagination-position="bottom"
            default-sort="id_producto"
            aria-next-label="Siguiente"
            aria-previous-label="Anterior"
            aria-page-label="vista"
            aria-current-label="Actual"
          >
            <b-table-column v-slot="props" label="Nombre completo">
              <div
                v-if="
                  props.row.nombre_th ||
                  props.row.apellido1_th ||
                  props.row.apellido2_th
                "
              >
                {{ props.row.nombre_th }} {{ props.row.apellido1_th }}
                {{ props.row.apellido2_th }}
              </div>
              <div v-else>--</div>
            </b-table-column>
            <b-table-column
              v-slot="props"
              label="Número de cédula"
              field="product.numero_cedula"
              sortable
            >
              {{ props.row.product.numero_cedula }}
            </b-table-column>
            <b-table-column v-slot="props" label="Procesado por">
              {{ props.row.procesado_por }}
            </b-table-column>
            <template slot="detail" slot-scope="props">
              <div class="columns">
                <div class="column is-full">
                  <div class="columns is-multiline">
                    <h6 class="column is-12 title is-5">Detalles</h6>
                    <div class="column is-6">
                      ID: <strong>{{ props.row.id_producto }}</strong>
                    </div>
                    <div class="column is-6">
                      Código de barras:
                      <strong v-if="props.row.product.codigo_barra">{{
                        props.row.product.codigo_barra
                      }}</strong>
                      <div v-else>--</div>
                    </div>
                    <div class="column is-6">
                      Código especial cliente:
                      <strong
                        v-if="props.row.product.codigo_especial_cliente"
                        >{{ props.row.product.codigo_especial_cliente }}</strong
                      >
                      <div v-else>--</div>
                    </div>
                    <h6 class="column is-12 title is-5">Contacto</h6>
                    <div class="column is-4">
                      Distrito:
                      <strong>{{
                        props.row.product.district.location_description
                      }}</strong>
                    </div>
                    <div class="column is-4">
                      Cantón:
                      <strong>{{
                        props.row.product.district.parent.location_description
                      }}</strong>
                    </div>
                    <div class="column is-4">
                      Provincia:
                      <strong>
                        {{
                          props.row.product.district.parent.parent
                            .location_description
                        }}</strong
                      >
                    </div>
                    <div class="column is-12">
                      Dirección entrega:
                      <strong v-if="props.row.product.direccion_entrega">{{
                        props.row.product.direccion_entrega
                      }}</strong>
                      <div v-else>--</div>
                    </div>
                    <h6 class="column is-12 title is-5">Comentarios</h6>
                    <div class="column is-12">
                      <strong v-if="props.row.comentarios">{{
                        props.row.comentarios
                      }}</strong>
                      <div v-else>--</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="has-text-grey has-text-centered">
                  <div>No se encontraron resultados</div>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
export default {
  name: "TableDetailsCancelledProducts",
  props: ["details"],
  data() {
    return {
      perPage: Number,
    };
  },
  created() {
    this.perPage = Number.parseInt(process.env.MIX_PAGINATE_DEFAULT);
  },
  mounted() {},
};
</script>
