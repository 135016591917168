<template>
    <button class="button is-primary has-top-margin-20" type="submit" @click="alert">Agregar documentos</button>
</template>

<script>
export default {
    methods: {
        alert(event){
            event.preventDefault();
            this.$buefy.dialog.confirm({
                    title: 'Atención',
                    message: '¿Está seguro de continuar y enviar la documentación adjuntada?<br><b> Esta acción no es reversible.</b>',
                    confirmText: 'Continuar',
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () => document.getElementById('form').submit()
            })
        }
    }
}
</script>
