<template>
  <section>
    <div class="message-header">
      <p>Documentos a recolectar</p>
      <span v-if="success">
        <b-field grouped>
          <b-dropdown v-model="imagesXPage" class="has-margin-right-10">
            <b-button
              icon-pack="far"
              icon-left="copy"
              slot="trigger"
              class="is-rimary"
            >
              Imágenes por hoja: {{ imagesXPage }}
              <b-icon icon="caret-down"></b-icon>
            </b-button>
            <b-dropdown-item :value="1">
              <div class="media">
                <b-icon
                  class="media-left"
                  icon="image"
                  icon-pack="far"
                ></b-icon>
                <div class="media-content">
                  <h3>1</h3>
                </div>
              </div>
            </b-dropdown-item>
            <b-dropdown-item :value="2">
              <div class="media">
                <b-icon
                  class="media-left"
                  icon="images"
                  icon-pack="far"
                ></b-icon>
                <div class="media-content">
                  <h3>2</h3>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            class="is-primary"
            :loading="isPDFLoading"
            icon-pack="far"
            icon-left="file-pdf"
            @click="createPDF"
            >Exportar a PDF</b-button
          >
        </b-field>
      </span>
    </div>
    <div class="message-body">
      <template inline-template>
        <b-table
          :data="detailsDocuments"
          :paginated="true"
          :pagination-simple="false"
          :per-page="perPage"
          :checkable="success"
          checkbox-position="left"
          :checked-rows.sync="selected"
          striped
          pagination-position="bottom"
          default-sort="id_producto"
          aria-next-label="Siguiente"
          aria-previous-label="Anterior"
          aria-page-label="vista"
          aria-current-label="Actual"
          class="has-margin-top-10"
        >
          <b-table-column
            v-slot="props"
            field="descripcion_documento_cliente"
            label="Descripción del documento"
            sortable
          >
            {{ props.row.descripcion_documento_cliente }}
            <a
              v-on:click.prevent.stop="showDocument(props.row.id_documento)"
              v-if="props.row.tipo_doc == 'pdf'"
              ><i class="fas fa-file-alt"></i> Ver documento PDF</a
            >
          </b-table-column>
          <b-table-column
            v-slot="props"
            field="fecha_upload"
            label="Fecha de carga"
            sortable
          >
            <div v-if="props.row.fecha_upload">
              {{
                new Date(props.row.fecha_upload).toLocaleDateString("es-CR")
              }}, {{ new Date(props.row.fecha_upload).toLocaleTimeString() }}
            </div>
            <div v-else>--</div>
          </b-table-column>
          <b-table-column v-slot="props" label="Comprobante Digital">
            <div v-if="props.row.url_doc" class="modal-picture">
              <swiper-trigger
                :img-url="img_path + props.row.url_doc"
                :img-description="props.row.descripcion_documento_cliente"
                :detail-documents="detailsDocuments"
              />
            </div>
            <div v-else>--</div>
          </b-table-column>
          <b-table-column v-slot="props" label="Comprobante anterior">
            <div v-if="props.row.url_doc_old" class="modal-picture">
              <swiper-trigger
                :img-url="img_path + props.row.url_doc_old"
                :img-description="props.row.descripcion_documento_cliente"
                :detail-documents="detailsDocuments"
                :old-document="true"
              />
            </div>
          </b-table-column>
          <b-table-column v-slot="props" v-if="success" label="Editar">
            <a
              :href="
                'asm_tracking_descargas_cliente_producto_entregado_editar_fotos/' +
                props.row.id_documento
              "
              >Editar imagen</a
            >
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="has-text-grey has-text-centered">
                <div>No se encontraron resultados</div>
              </div>
            </section>
          </template>
        </b-table>
      </template>
    </div>
  </section>
</template>

<script>
export default {
  name: "TableDetailsClientDocuments",
  props: ["detailsDocuments", "success", "api", "page"],
  data() {
    return {
      selected: [],
      isPDFLoading: false,
      img_path: "",
      imagesXPage: 1,
      method: "",
      perPage: Number,
    };
  },
  created() {
    this.perPage = Number.parseInt(process.env.MIX_PAGINATE_DEFAULT);
  },
  mounted() {
    this.img_path = process.env.MIX_IMG_PATH;
  },
  methods: {
    createPDF() {
      var idDocumentos = [];
      var idProducto = null;
      this.isPDFLoading = true;

      if (this.selected.length == 0) {
        idProducto = this.detailsDocuments[0].id_producto;
      } else {
        this.selected.forEach(function (document) {
          idDocumentos.push(document.id_documento);
        });
      }

      axios
        .get(this.api, {
          params: {
            idDocumentos: idDocumentos,
            idProducto: idProducto,
            imagesXPage: this.imagesXPage,
          },
        })
        .then((response) => {
          this.isPDFLoading = false;

          window.open(response.request.responseURL, "_blank");
        })
        .catch((error) => {
          this.isPDFLoading = false;
          console.log(error);
          alert("Falló descarga del PDF");
        });
    },

    // showDocument(id_documento) {
    //   axios
    //     .get("/api/asm/asm_tracking_consultas_detalles_cliente/contrato", {
    //       params: { idDocumento: id_documento },
    //     })
    //     .then((response) => {
    //       window.open(response.request.responseURL, "_blank");
    //     });
    // },

    showDocument(id_documento) {
      axios
        .get("/app/asm/prueba_documento", {
          params: { idDocumento: id_documento },
        })
        .then((response) => {
          window.open(response.request.responseURL, "_blank");
        });
    },
  },
};
</script>
