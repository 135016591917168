<template>
  <div>
    <b-field grouped>
      <b-checkbox name="falla" type="is-blue" v-model="applicationFailure">
        La falla se presenta durante el uso de alguna aplicación en específico?
      </b-checkbox>
    </b-field>
    <b-field grouped>
      <b-field label="Describa la falla" label-position="on-border" expanded>
        <b-input
          name="fallaaplicacion_des"
          :disabled="!applicationFailure"
          :required="applicationFailure"
          validation-message="Por favor, ingrese los detalles"
        >
        </b-input>
      </b-field>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "ReportApplicationFailure",
  data() {
    return {
      applicationFailure: false,
    };
  },
};
</script>

<style>
</style>
