<template>
    <div>
        <div class="notification">
            <b-loading :is-full-page="false" v-model="loading" />
            <header class="is-size-5 has-text-info has-padding-bottom-5"><strong>{{ title }}</strong></header>
            <div class="columns has-margin-top-5">
                <div class="column is-2">
                    <b-icon size="is-large" class="has-padding-5 has-text-left" :type="type" :icon="icon"></b-icon>
                </div>
                <div class="column has-text-right is-10">
                    <span class="is-size-3 has-text-info has-padding-top-5 has-padding-bottom-5"><strong>{{ value }}</strong></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DataDisplay',
        props: {
            title: String,
            type: String,
            icon: String,
            method: String,
            api: String,
        },
        data() {
            return {
                value: String,
                loading: false,
            }
        },
        created () {
            this.value = 0
        },
        mounted() {
            this.loading = true

            axios
                .post(this.api, { method: this.method, callTo: 'getDisplayData' })
                .then(response => {
                    this.value = response.data.dataDisplay
                    this.loading = false
                })
                .catch(error => {
                    console.log(error)
                    this.loading = false
                })
        }
    }
</script>
