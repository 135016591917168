<template>
    <b-button :loading="isPDFLoading" type="is-red" icon-pack="far" icon-left="file-pdf" @click="generatePDF">Exportar a PDF</b-button>
</template>

<script>
import FileSaver from 'file-saver'

    export default {
        name: "PdfExporter",
        props: ['values', 'apicall',],
        data() {
            return {
                isPDFLoading: false,
                request: new Object(),
            }
        },
        mounted() {
            if (!Array.isArray(this.values))
            {
                this.request = this.values;
                delete this.request['_token']
            }
        },
        methods: {
            generatePDF()
            {
                this.isPDFLoading = true

                axios
                    .get(this.apicall, {
                        params: this.request
                    })
                    .then(response => {
                        this.isPDFLoading = false

                        window.open(response.request.responseURL, '_blank')
                    })
                    .catch(error=> {
                        console.log(error)
                        this.isPDFLoading = false
                        alert("Falló la descarga del PDF, si sigue pasando contacte con el sysadmin")
                    })
            }
        }
    }
</script>
