<template>
    <div class="card">
        <b-loading :is-full-page="false" v-model="loading"></b-loading>
        <header class="card-header">
            <div class="card-header-title">{{ chartTitle }}</div>
            <!-- <a href="#" class="card-header-icon">
                <span class="icon">
                    <i class="fas fa-filter"></i>
                </span>
            </a> -->
        </header>
        <div class="card-content">
            <div class="content">
                <div class="legendsContainer">

                </div>
                <canvas :id="chartId" width="500" height="500"></canvas>
            </div>
        </div>
        <footer class="card-footer">
            <a href="" @click.prevent.stop="toExcel" class="card-footer-item">
                <span class="icon">
                    <i class="fas fa-file-excel"></i>
                </span>
                Datos a Excel
            </a>
            <!-- <a href="#" class="card-footer-item">
                <span class="icon">
                    <i class="fas fa-lock-open"></i>
                </span>
                Aplicar filtros
            </a> -->
        </footer>
    </div>

    <!-- <div class="notification">
        <b-loading :is-full-page="false" v-model="loading"></b-loading>
        <canvas :id="chartId" style="width: 100%; height: 100%;"></canvas>
    </div> -->
</template>

<script>
    //! TODO implementar treeshake, ver migraciones de chart v2 a 3
    import Chart from 'chart.js/auto'
    import ChartDataLabels from 'chartjs-plugin-datalabels'
    import FileSaver from 'file-saver'

    export default {
        name: "ChartDisplay",
        props: {
            chartId: String,
            chartType: String,
            chartTitle: String,
            displayLegend: {
                type: Boolean,
                default: true
            },
            method: String,
            api: String,
            excelApi: String,
            chartFilterParameters: {},
            reloadOnChange: {
                type: Boolean,
                default: true
            },
            showDetails: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                chartFilter: {},
                chartData: {},
                chartDisplay: null,
                loading: false,
                loaded: false,
            }
        },
        watch: {
            chartFilterParameters: {
                inmmediate: true,
                handler(val, oldVal) {
                    if (this.reloadOnChange)
                        this.getChartData()
                    else if (!this.loaded)
                        this.getChartData()
                },
                deep: true,
            }
        },
        created() {},
        mounted() {
            let chart = document.getElementById(this.chartId).getContext('2d')
            let labelPlugin = []
            if (this.showDetails) {
                labelPlugin = [ChartDataLabels]
            }
            this.chartDisplay = new Chart(chart, {
                type: this.chartType,
                options: {
                    title: {
                        display: false
                    },
                    responsive: true,
                    maintainAspecRadio: false,
                    legend: {
                        display: this.displayLegend,
                        position: 'bottom'
                    },
                    plugins: {
                        datalabels: {
                            color: '#fff',
                            display: 'auto',
                            clamp: true,
                            anchor: 'end',
                            backgroundColor: function (context) {
                                return context.dataset.backgroundColor;
                            },
                            borderColor: '#fff',
                            borderRadius: 25,
                            borderWidth: 2,
                            formatter(value, ctx) {
                                return (value * 100 / _.sum(ctx.chart.data.datasets[0].data.map((d) => parseInt(d)))).toFixed(2) + '%'
                            }
                        }
                    },
                    layout: {
                        padding: {
                            top: 25,
                            left: 25,
                            rigth: 25,
                            bottom: 25
                        },
                        scales: {
                            x: {
                                display: ((this.chartType != 'doughnut') && (this.chartType != 'pie'))

                            },
                            y: {
                                display: false
                            }
                        }
                    },

                },
                plugins: labelPlugin
            })
            this.getChartData()
        },
        methods: {
            getChartData() {
                if (!(Object.keys(this.chartFilterParameters).length === 0 && this.chartFilterParameters.constructor ===
                        Object)) {
                    this.loading = true
                    this.chartFilter = _.cloneDeep(this.chartFilterParameters)
                    this.chartFilter['method'] = this.method
                    this.chartFilter['callTo'] = 'getChartData'

                    axios
                        .post(this.api, this.chartFilter)
                        .then(response => {
                            this.loading = false
                            this.loaded = true
                            this.chartData = response.data.chartData
                            this.chartDisplay.data = this.chartData
                            this.chartDisplay.update()
                        })
                        .catch(error => {
                            console.log(error)
                            this.loading = false
                        })
                }
            },
            toExcel() {
                if (!(Object.keys(this.chartFilterParameters).length === 0 && this.chartFilterParameters.constructor ===
                        Object)) {
                    this.loading = true
                    this.chartFilter = _.cloneDeep(this.chartFilterParameters)
                    this.chartFilter['method'] = this.method
                    this.chartFilter['toExcel'] = true
                    this.chartFilter['callTo'] = 'getChartData'

                    axios
                        .get(this.excelApi, {
                            params: this.chartFilter,
                            responseType: 'blob',
                        })
                        .then(response => {
                            this.loading = false
                            let filename
                            filename = response.headers["content-disposition"].substring(response.headers[
                                "content-disposition"].indexOf('"') + 1, response.headers[
                                "content-disposition"].lastIndexOf('"'))

                            let file = new Blob([response.data], {
                                type: response.headers["content-type"]
                            })
                            FileSaver.saveAs(file, filename)
                        })
                        .catch(error => {
                            console.log(error)
                            this.loading = false
                        })
                }
            }
        }
    }

</script>
