<template>
    <b-field grouped>
        <div :class="['file', 'has-name', 'is-fullwidth']">
            <label class="file-label">
                <input ref="file" type="file" class="file-input archivo_cargar" :required="required" :name="archivoNombre" @change="nombreArchivo" :accept="accept">
                <span class="file-cta" :style="`background-color: ${myCustomColor}; color:white;`">
                    <span class="file-icon">
                        <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label">
                        Subir archivo
                    </span>
                </span>
                <span class="file-name">
                    <p v-if="!filename">Seleccione un archivo</p>
                    <p v-else>{{ filename }}</p>
                </span>
            </label>
        </div>
        <b-field>
            <button type="button" v-if="filename" @click="resetFile" class="button is-success has-top-margin-20"><i class="far fa-trash-alt"></i></button>
        </b-field>
    </b-field>
</template>

<script>
    export default {
        name: 'FileUploader',
        props: {
            accept: {
                default: '.pdf,application/pdf,image/*',
                type: String
            },
            required: {
                default: false,
                type: Boolean
            },
            data:{

            },
            customColor: {
                default: '#e65b09 !important',
                type: String
            },
        },
        data() {
            return {
                filename: null,
                archivoNombre: 'archivo',
                myCustomColor: '#e65b09 !important',
                file:null
            }
        },
        mounted() {
            this.archivoNombre = 'archivo'+this.data.index
            this.myCustomColor = this.customColor
        },
        methods: {
            nombreArchivo(e)
            {
                console.log(this.file)
                this.filename = e.srcElement.files[0].name
            },

            resetFile(){
                this.$refs.file.value=null;
                this.filename = null
            }
        },

        watch:{
            filename:function(){
                if(this.filename)
                    this.myCustomColor  = '#3a9605 !important'
                else
                    this.myCustomColor  = '#e65b09 !important'
            }
        }
    }
</script>
